/**
 * @function generateUrlWithParams
 * @description url-encode params and attach to end of url string
 * @param {String} url -
 * @param {Array} params - string params in key=value format
 * @returns {String} url with params included
 */
function generateUrlWithParams(url, params) {
	return (
		url +
		params.reduce((acc, val, index) => {
			if (index < params.length - 1) {
				return acc + val + '&';
			}
			return acc + val;
		}, '?')
	);
}

/**
 * @function generateResizeLink
 * @description generate an Akamai Image link
 * @param {String} imagePath - akamai image path
 * @param {Number|String} width of image - number or string that can be parsed to an integer
 * @param {Number|String} height of image - number, string that can be parsed to an integer
 * or the string, "auto"
 * @param {Boolean} [crop] - to crop or not to crop
 * @param {Number|String} [width2x] of image - string that can be parsed to an integer; the maximum 2x width the image can be, otherwise defaults to 2 * width
 * @returns {Object} src and srcset attributes to be added to an img tag
 */
export default (imagePath, width, height = 'auto', crop = false, width2x = null) => {
	if (!imagePath) {
		return {
			src: '',
			srcset: ''
		};
	}

	if (typeof imagePath !== 'string') {
		console.error('generateResizeLink function requires a string for its first argument');
	}

	// generateResizeLink function requires a number for its second argument
	if (typeof width !== 'number') {
		width = parseInt(width);
	}

	// generateResizeLink function requires a number or string "auto" for its third argument
	if (typeof height !== 'number' && height !== 'auto') {
		height = parseInt(height);
	}

	// calculate 2x dimensions
	let height2x;
	width2x = parseInt(width2x);
	if (isNaN(width2x)) {
		// if custom 2x width isn't available, set to default values
		width2x = width * 2;
		height2x = height === 'auto' ? height : height * 2;
	} else {
		// set the proportional 2x height if custom 2x width is available
		height2x = height === 'auto' ? height : width2x * (height / width);
	}

	width = isNaN(width) ? width : Math.round(width);
	width2x = isNaN(width2x) ? width2x : Math.round(width2x);
	height = isNaN(height) ? height : Math.round(height);
	height2x = isNaN(height2x) ? height2x : Math.round(height2x);

	const fitParam = crop ? 'fit=around%7C' : 'fit=inside%7C';
	const paramDimensions1 = fitParam + width + ':' + height;
	const paramDimensions2 = fitParam + width2x + ':' + height2x;
	const params1 = [paramDimensions1, 'output-quality=90'];
	const params2 = [paramDimensions2, 'output-quality=90'];

	if (crop) {
		params1.push('crop=' + width + ':' + height + ';center,top');
		params2.push('crop=' + width2x + ':' + height2x + ';center,top');
	}
	const src = generateUrlWithParams(imagePath, params1);
	const srcset1 = generateUrlWithParams(imagePath, params1) + ' 1x';
	const srcset2 = generateUrlWithParams(imagePath, params2) + ' 2x';
	const srcset = `${srcset1}, ${srcset2}`;

	return {
		src,
		srcset
	};
};
