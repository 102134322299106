import React, { useEffect, createRef } from 'react';
import { useParamToggleContext } from '@/components/param-toggle/ParamToggleContext';
import { Ad } from './mps';
import breakpointType from '@/utils/breakpointType';
import './MpsAd.scss';
import { string, bool, func } from 'prop-types';
import { useMpsContext } from './MpsContext';
import { withErrorBoundary } from '../error/ErrorBoundary';

/**
 * @function MpsAd
 * @param {Object} props
 * @param {String} props.slot
 * @param {String} [props.id] The div id
 * @param {String} [props.keywords]
 * @param {Boolean} [props.repeatable]
 * @param {Boolean} [props.isDesktopOnly]
 * @param {Boolean} [props.isHiddenDesktop]
 * @param {Boolean} [props.isHiddenMobile]
 * @param {Boolean} [props.isHiddenOnError] Hide the div on any error
 * @param {Boolean} [props.isMobileOnly]
 * @param {Boolean} [props.isTabletOnly]
 * @param {Function} [props.success] Success Callback to be used when
 * @param {Function} [props.error] Error Callback
 * @param {String} [props.className]
 * @param {Boolean} [props.centered]
 * @return {React.ReactElement}
 */
const MpsAd = ({
	id,
	slot,
	keywords,
	className,
	centered,
	isDesktopOnly,
	isHiddenDesktop,
	isHiddenMobile,
	isHiddenOnError,
	isMobileOnly,
	isTabletOnly,
	repeatable,
	success,
	error
}) => {
	const { isInWidget } = useMpsContext();
	const ref = createRef();
	const { MPS_ENABLED, THIRD_PARTY_ENABLED } = useParamToggleContext();
	const isDisabled = !(MPS_ENABLED && THIRD_PARTY_ENABLED);

	// On Mount request new ad
	useEffect(() => {
		const breakpoint = breakpointType(window.innerWidth);

		if (
			isDisabled ||
			(isDesktopOnly && (breakpoint === 'mobile' || breakpoint === 'tablet')) ||
			(isHiddenDesktop && breakpoint === 'desktop') ||
			(isHiddenMobile && breakpoint === 'mobile') ||
			(isMobileOnly && (breakpoint === 'desktop' || breakpoint === 'tablet')) ||
			(isTabletOnly && breakpoint === 'tablet')
		) {
			return;
		}

		const MpsAd = new Ad({
			id: id ? '#' + id : null,
			el: ref.current,
			slot,
			isInWidget,
			targeting: keywords,
			repeatable,
			success,
			error: (args) => {
				if (isHiddenOnError) ref.current.classList.add('is-hidden');
				error(args);
			}
		});
		// On unmount, destroy ad
		return () => {
			MpsAd.destroy();
		};
	}, []);

	let extendedClassName = className;
	if (centered) extendedClassName += ' mps--centered';
	if (isDesktopOnly) extendedClassName += ' mps--desktop-only';
	if (isHiddenDesktop) extendedClassName += ' mps--hide-desktop';
	if (isHiddenMobile) extendedClassName += ' mps--hide-mobile';
	if (isMobileOnly) extendedClassName += ' mps--mobile-only';
	if (isTabletOnly) extendedClassName += ' mps--tablet-only';

	return MPS_ENABLED && THIRD_PARTY_ENABLED ? (
		<div ref={ref} id={id} className={extendedClassName} data-slot={slot} />
	) : null;
};

MpsAd.defaultProps = {
	centered: false,
	className: '',
	isDesktopOnly: false,
	isHiddenDesktop: false,
	isHiddenMobile: false,
	isHiddenOnError: false,
	isMobileOnly: false,
	isTabletOnly: false,
	repeatable: false
};

MpsAd.propTypes = {
	slot: string.isRequired,
	id: string,
	keywords: string,
	className: string,
	centered: bool,
	success: func,
	error: func,
	repeatable: bool,
	isWidget: bool,
	isHiddenDesktop: bool,
	isHiddenMobile: bool,
	isHiddenOnError: bool,
	isDesktopOnly: bool,
	isMobileOnly: bool,
	isTabletOnly: bool
};

MpsAd.displayName = 'MpsAd';

export default withErrorBoundary(MpsAd);
