import React, { useEffect, useRef, Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParamToggleContext } from '@/components/param-toggle/ParamToggleContext';
import breakpointType from '@/utils/breakpointType';
import { usePageContext } from '@/components/layout/page/PageContext';
import { useResizePageObserver } from '@/components/layout/page/ResizePageObserver';
import mpsInit from './mpsInit';
import { string, array } from 'prop-types';

const customConfigString = `window.onload = function() {
	mps._detect = mps._detect || {};
	mps._detect.padding = 500;
	console.log("custom MPS detect loaded");
};`;

/**
 * @funtion MpsCategoryConfig
 * @param {Object} props
 * @param {String} [props.topCategory]
 * @param {Array} props.categories or categories[0].name
 * @param {String} [props.categoryType] 90% of pages use "topics"
 * @return {React.ReactElement}
 */
const MpsCategoryConfig = ({ categoryType, categories, topCategory }) => {
	const { mpsPageType } = usePageContext();
	if (!(categories?.length || topCategory) || !categoryType) return null;

	const topCat = topCategory || categories[0]?.name;
	const normalizeCat = topCat?.toLowerCase()?.replace(/_|-/, ' ');
	const topics = categories
		.map(({ name }) => name && name.toLowerCase() + '|')
		.join('')
		.replace(/\|$/, '');
	return (
		<Fragment>
			<Helmet>
				<script>{`
				mpscall['cag[primary]'] = "${normalizeCat}";
				mpscall.cat = '${categoryType}|${normalizeCat}|${mpsPageType}';
			`}</script>
			</Helmet>
			{categories.length > 1 && <MpsConfigType keyName="cag[topics]" val={topics} />}
		</Fragment>
	);
};

MpsCategoryConfig.displayName = 'MpsCategoryConfig';

MpsCategoryConfig.defaultProps = {
	categoryType: 'topics',
	categories: []
};

MpsCategoryConfig.propTypes = {
	categoryType: string,
	topCategory: string,
	categories: array
};

/**
 * @funtion MpsConfigType
 * @param {Object} props
 * @param {String} props.keyName
 * @param {String} [props.val]
 * @return {React.ReactElement}
 */
const MpsConfigType = ({ keyName, val }) => {
	if (!val) return null;
	return (
		<Helmet>
			<script>{`mpscall['${keyName}'] = "${val}";`}</script>;
		</Helmet>
	);
};

MpsConfigType.displayName = 'MpsConfigType';

MpsConfigType.propTypes = {
	keyName: string.isRequired,
	val: string
};

/**
 * @funtion MpsDataConfig
 * @param {Object} props
 * @param {Array} [props.categories]
 * @param {String} [props.topCategory]
 * @param {String} [props.categoryType] 90% of pages use "topics"
 * @param {String} [props.contentId] The page id for pages we want to also allow page level id tracking
 * @param {String} [props.pageAdKeyword]
 * @return {React.ReactElement}
 */

export const MpsDataConfig = ({
	categoryType,
	contentId,
	categories,
	pageAdKeyword,
	topCategory
}) => {
	const { MPS_ENABLED, THIRD_PARTY_ENABLED } = useParamToggleContext();
	return MPS_ENABLED && THIRD_PARTY_ENABLED ? (
		<Fragment>
			<MpsCategoryConfig
				categoryType={categoryType}
				categories={categories}
				topCategory={topCategory}
			/>
			<MpsConfigType keyName="content_id" val={contentId} />
			<MpsConfigType keyName="cag[akw]" val={pageAdKeyword} />
		</Fragment>
	) : null;
};

MpsDataConfig.displayName = 'MpsDataConfig';

MpsDataConfig.propTypes = {
	categoryType: string,
	categories: array,
	contentId: string,
	pageAdKeyword: string,
	topCategory: string
};

/**
 * @function config
 * @description The inline mps config script that gets executed when SSR
 * @param {Object} context
 * @param {String} context.path
 * @param {String} context.mpsPageType
 * @return {String}
 */
const config = ({ path, mpsPageType }) => {
	const simplePath = path.split('?')[0].replace(/\/$/, '');
	const { CUSTOM_MPS_ENABLED } = useParamToggleContext();

	return `(function(){
		// Get adEdition set from Akamai
	   var adEditionCookie = new RegExp("adEdition=([^;]+)").exec(document.cookie);
	   var adEdition = (adEditionCookie !== null) ? unescape(adEditionCookie[1]) : 'us';
   
	   window.mpscall = {
		   site: 'e-web',
		   path: '${simplePath}',
		   type: '${mpsPageType}',
		   'cag[zoneid]': adEdition
	   };
   
	   // Set the dfp network based on the adEdition, whether its "US" || "LATAM" || "INTL"
	   mpscall['cag[dfpnetwork]'] = /LA|BR/i.test(adEdition) ? 'LATAM' : /US/i.test(adEdition) ? 'US' : 'INTL';
   
	   window.mpsopts = {
		   host: 'mps.nbcuni.com'
	   };
	   window.mps=window.mps||{};mps.eol=mps.eol||{};mps._ext=mps._ext||{};mps._adsheld=[];mps._queue=mps._queue||{};
	   mps._queue.mpsloaded=mps._queue.mpsloaded||[];mps._queue.mpsinit=mps._queue.mpsinit||[];mps._queue.gptloaded=mps._queue.gptloaded||[];mps._queue.adload=mps._queue.adload||[];mps._queue.adclone=mps._queue.adclone||[];mps._queue.adview=mps._queue.adview||[];mps._queue.refreshads=mps._queue.refreshads||[];mps.__timer=Date.now()||function(){return+new Date};mps.__intcode="v2";if(typeof mps.getAd!="function")mps.getAd=function(adunit){if(typeof adunit!="string")return false;
	   var slotid="mps-getad-"+adunit.replace(/\W/g,"");if(!mps._ext||!mps._ext.loaded){mps._queue.gptloaded.push(function(){typeof mps._gptfirst=="function"&&mps._gptfirst(adunit,slotid);mps.insertAd("#"+slotid,adunit)});mps._adsheld.push(adunit)}return'<div id="'+slotid+'" class="mps-wrapper" data-mps-fill-slot="'+adunit+'"></div>'};(function(){head=document.head||document.getElementsByTagName("head")[0],mpsload=document.createElement("script");mpsload.src="//"+mpsopts.host+"/fetch/ext/load-"+mpscall.site+".js?nowrite=2";mpsload.id="mps-load";mpsload.async=1;head.insertBefore(mpsload,head.firstChild)})();

	   mps._queue.adview.push(function(eo) {
		window.dispatchEvent(new CustomEvent('mpsadviewed', { detail: { slot: eo._mps.adslot } }));
	  });
	  
	   ${CUSTOM_MPS_ENABLED ? customConfigString : ''}
   })()`;
};

/**
 * @function MpsConfig
 * @description. Do not remove ID, necessary for helmet to remove duplicates when a page 404's
 * @return {React.ReactElement}
 */
export const MpsConfig = () => {
	const isLoaded = useRef();
	const { path, mpsPageType } = usePageContext();
	const { MPS_ENABLED, THIRD_PARTY_ENABLED } = useParamToggleContext();
	const { width } = useResizePageObserver();
	const type = breakpointType(width);
	// Initialize mps on mount
	useEffect(() => {
		if (MPS_ENABLED && THIRD_PARTY_ENABLED) {
			mpsInit(() => {
				isLoaded.current = true;
			});
		}
	}, []);
	// Apply responsiveness when breakpoint type changes
	useEffect(() => {
		if (
			typeof window === 'undefined' ||
			!isLoaded.current ||
			(MPS_ENABLED && THIRD_PARTY_ENABLED) === false
		) {
			return;
		}
		mps && mps.responsiveApply();
	}, [type]);

	return MPS_ENABLED && THIRD_PARTY_ENABLED ? (
		<Helmet>
			<script id="mps-config">{config({ path, mpsPageType })}</script>
		</Helmet>
	) : null;
};

MpsConfig.displayName = 'MpsConfig';

/**
 * @function MpsFooter
 * @return {React.ReactElement}
 */
export const MpsFooter = () => {
	const { MPS_ENABLED, THIRD_PARTY_ENABLED } = useParamToggleContext();
	return MPS_ENABLED && THIRD_PARTY_ENABLED ? (
		<script
			dangerouslySetInnerHTML={{
				__html: 'mps.writeFooter && mps.writeFooter();'
			}}
		/>
	) : null;
};

MpsFooter.displayName = 'MpsFooter';
