// DO NOT USE THIS FILE DIRECTLY GO THROUGH mps.js

/**
 * @constant slotCallbackCache
 * @description DO NOT EXPORT. exporting this object will make it cached for the entire application. We want this object to be new per page.
 */

const slotCallbackCache = {};

/**
 * @function getSlotCallbacks
 * @description gets the slotcallback cache
 * @return {Object}
 */
export const getSlotCallbacks = () => {
	return slotCallbackCache;
};

/**
 * @function setSlotCallbacks
 * @description Sets the callbacks for a specific slot type. Supports multiple callbacks per slot
 * @param {String} slot - The slot
 * @param {Object} callbacks - An object with callbacks ex. success || error || any eventkey
 */
export const setSlotCallbacks = (slot, callbacks = {}) => {
	slotCallbackCache[slot] = slotCallbackCache[slot] || {};

	Object.keys(callbacks).forEach(key => {
		if (typeof callbacks[key] !== 'function') return;
		if (typeof slotCallbackCache[slot][key] === 'undefined') {
			slotCallbackCache[slot][key] = [];
		}
		slotCallbackCache[slot][key].push(callbacks[key]);
	});
};

/**
 * @function publishSlotCallbacks
 * @description Executes the slot callbacks - Acts as a Publisher for events
 * @param {String} slot - The slot
 * @param {Object} eo The eo object passed down from the mps._queue.adload callback
 */
export const publishSlotCallbacks = (slot, eo = {}) => {
	if (typeof slotCallbackCache[slot] === 'undefined') return;

	Object.keys(slotCallbackCache[slot]).forEach(key => {
		if (
			(eo.isEmpty && key === 'error') ||
			(eo.isEmpty === false && key === 'success') ||
			(slotCallbackCache[slot][key].length && key !== 'error' && key !== 'success')
		) {
			slotCallbackCache[slot][key].forEach((func, index) => {
				typeof func === 'function' && func(eo);
			});
		}
	});
};

/**
 * @method removeSlotCallback
 * @desc Removes the slots callbacks
 * @param {String} slot - The slot (REQUIRED)
 * @param {String} [type] - The type of callback ex. success || error || any event type
 * @param {Function} [cb] - A specified callback
 */
export const removeSlotCallbacks = (slot, type, cb) => {
	// If it isn't an array don't continue
	if (
		typeof slotCallbackCache[slot] === 'undefined' ||
		(type && !Array.isArray(slotCallbackCache[slot][type]))
	) {
		return;
	}

	// We want to remove all callbacks
	if (typeof type === 'undefined') {
		delete slotCallbackCache[slot];
	}
	// If no callback provided, unsubscribe from the slot type and reset back to an empty array
	else if (typeof cb === 'undefined') {
		slotCallbackCache[slot][type] = [];
	} else {
		// Remove specified callback if it exists
		slotCallbackCache[slot][type].forEach((func, index) => {
			slotCallbackCache[slot][type].splice(index, 1);
		});
	}
};
