import React from 'react';
import { usePageContext } from '@/components/layout/page/PageContext';
import Translate from '@/components/i18n/Translate';
import intlUrl from '@/components/i18n/intlUrl';
import './ShowsButtons.scss';
import { useNavContext } from '@/components/layout/navigation/NavContext';

const ShowsButtons = () => {
	const { nav } = useNavContext();
	const link = nav?.callToAction?.links?.[0] || {};

	const { displayTitle, reference } = link;
	const { edition } = usePageContext();
	return (
		<div className="main-menu-shows__buttons">
			<a
				className="main-menu-shows__cta-button"
				data-hook="main-menu-shows-cta-button"
				data-analytics-linklocation="menu-overlay-shows"
				data-analytics-linkname="see-all"
				href={intlUrl(edition, '/shows')}
			>
				<span className="main-menu-shows__cta-button-title">
					<Translate word="SEE_ALL_SHOWS" />
				</span>
			</a>
			{displayTitle && reference?.uri ? (
				<a
					className="main-menu-shows__cta-button"
					data-hook="main-menu-shows-cta-button"
					data-analytics-linkname="watch-live-cta"
					data-analytics-linklocation="menu-overlay-shows"
					href={reference.uri}
				>
					<span className="main-menu-shows__cta-button-title">{displayTitle}</span>
				</a>
			) : null}
		</div>
	);
};

ShowsButtons.displayName = 'ShowsButtons';

export default ShowsButtons;
