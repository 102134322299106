export { default as Ad } from './Ad';
export * from './mpsSlotCallbacks';

/**
 * @function refreshSlot
 * @description An helper function for refreshing ads that have already been rendered onto the page
 * @param {String} slotname of the slot to be refreshed ex. topbanner
 */
export const refreshSlot = (slotname) => {
	if (typeof slotname !== 'string' || typeof mps === 'undefined') return;
	// console.log(`MPS Refreshing ${slotname}`);
	mps?.refreshAds(slotname);
};
