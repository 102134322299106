import React from 'react';
import { Helmet } from 'react-helmet-async';
import { usePageContext } from '@/components/layout/page/PageContext';
import { APP_HOSTNAME } from '@/config/config';

/**
 * @function trailingEdition
 * @description creates edition string to go at end of title depending on edition
 * @param {String} edition
 * @returns {String}
 */
const trailingEdition = (edition) => {
	const hasTrailingEdition = /^(CA|AU|UK|AP|MX|CO|VE|AR)$/i.test(edition);

	return hasTrailingEdition ? ` - ${edition.toUpperCase()}` : '';
};

/**
 * @function HeadMeta
 * @return {React.ReactElement}
 */
const HeadMeta = ({ isDetail }) => {
	const { edition, path, translations } = usePageContext();

	// remove query params from path
	const canonicalPath = path.split('?')[0];

	// add protocol, hostname, and then remove any trailing slash
	const canonicalUrl = `https://${APP_HOSTNAME}${canonicalPath}`.replace(/\/$/, '');

	// Remove suffix and edition from (article|video|gallery) detail pages
	return isDetail ? (
		<Helmet
			titleTemplate={'%s'}
		>
			<link rel="canonical" href={canonicalUrl} />
			<body className={`edition--${edition}`} />
		</Helmet>
	) : (
		<Helmet
			titleTemplate={`%s - ${translations?.TITLE_SUFFIX || ''}${trailingEdition(edition)}`}
		>
			<link rel="canonical" href={canonicalUrl} />
			<body className={`edition--${edition}`} />
		</Helmet>
	);
};

HeadMeta.displayName = 'HeadMeta';

export default HeadMeta;
