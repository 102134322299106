import kebabCase from 'lodash-es/kebabCase';
import deburr from 'lodash-es/deburr';

/**
 * @function createAnalyticsString
 * @description converts special Latin characters to basic Latin
 * characters, and replaces whitespace and capital
 * letters with hyphenated lowercase
 * @param {String} text
 * @returns {String}
 */
export default (text) => kebabCase(deburr(text));
