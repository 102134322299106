import React, { useState } from 'react';
import DockableVideoContext from './DockableVideoContext';
import { element } from 'prop-types';

/**
 * @function DockableVideoProviderWrap
 * @description Context wrap for dockable video
 * @param {Object} props
 * @param {React.ReactElement} props.children
 * @return {React.ReactElement}
 */
const DockableVideoProviderWrap = ({ children }) => {
	const [dockedPlayerId, updateDockedPlayerId] = useState('');
	return (
		<DockableVideoContext.Provider value={{ dockedPlayerId, updateDockedPlayerId }}>
			{children}
		</DockableVideoContext.Provider>
	);
};

DockableVideoProviderWrap.propTypes = {
	children: element.isRequired
};

DockableVideoProviderWrap.displayName = 'DockableVideoProviderWrap';

export default DockableVideoProviderWrap;
