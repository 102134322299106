import React from 'react';
import { useNavContext } from '@/components/layout/navigation/NavContext';
import { usePageContext } from '@/components/layout/page/PageContext';
import { APP_HOSTNAME } from '@/config/config';
import createAnalyticsString from '@/utils/createAnalyticsString';
import StructuredData from '@/components/structured-data/StructuredData';
import Translate from '@/components/i18n/Translate';
import { bool, string } from 'prop-types';
import striptags from 'striptags';
import NavBarIcons from './NavBarIcons';
import './NavBar.scss';

/**
 * @function NavBarLink
 * @description provides navbar link translated into the appropriate international edition's language
 * @param {String} linkname - analytics designation for link
 * @param {String} link - uri or path to link to
 * @param {Boolean} [shouldTranslate] - true if text prop is hardcoded and not coming from data source
 * @param {String} text - type to be linked, e.g. "News"
 * @returns {React.ReactElement}
 */
const NavBarLink = ({ linkname, link, shouldTranslate, text }) => {
	const { path } = usePageContext();
	const isActive = path === link;
	const formattedLinkName = createAnalyticsString(linkname);

	return isActive ? (
		<span className="nav-bar__link nav-bar__link--active" data-hook="nav-bar-link">
			{shouldTranslate ? <Translate word={text} /> : text}
		</span>
	) : (
		<a
			data-analytics-linklocation="navigation"
			data-analytics-linkname={formattedLinkName}
			data-testid={formattedLinkName}
			data-hook="nav-bar-link"
			className="nav-bar__link"
			href={link}
		>
			{shouldTranslate ? <Translate word={text} /> : text}
		</a>
	);
};

NavBarLink.propTypes = {
	linkname: string.isRequired,
	link: string.isRequired,
	shouldTranslate: bool,
	text: string.isRequired
};

NavBarLink.displayName = 'NavBarLink';

/**
 * @function NavBar
 * @description Provides main header navigation
 * @returns {React.ReactElement}
 */
const NavBar = () => {
	const { nav } = useNavContext();
	const { edition, path } = usePageContext();
	const homeLink = edition === 'us' ? '/' : `/${edition}`;

	const structuredDataNames = [];
	const structuredDataUrls = [];
	const hotTopicsLinks = nav?.hotTopics?.links || [];

	return (
		<nav className="nav-bar">
			<div className="nav__container">
				<div className="nav-bar__logo">
					<a
						href={homeLink}
						data-analytics-linklocation="navigation"
						data-analytics-linkname="e-logo"
						data-testid="nav-bar-brand"
					>
						<svg
							className="nav-bar__logo-img"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 745 441"
						>
							<path
								d="M679.45,141.92c0-8.95,4.56-14.3,13.45-14.3,10.73,0,13,8.78,13,16.14v29.89L745,163.1V144.87c0-32.51-18.94-53.52-50.59-53.52-17.8,0-30.84,5.81-40.58,16.81-9,10.12-13.69,24.34-13.69,41.13,0,57.25,64.92,102,64.92,150.37,0,10.86-5,16.14-15.43,16.14-9.7,0-14.62-5.8-14.62-17.25V266.16l-39.53,10.57v20.72c0,33,19.71,53.52,53.87,53.52,14.94,0,27-4,36.74-12.48,11.47-10.06,18.28-23,18.28-41.71,0-61.14-64.92-104.34-64.92-154.86"
								fill="currentColor"
							/>
							<polygon
								points="583.43 95.46 573.22 181.39 566.58 259.03 565.24 214.59 551.1 95.46 517.87 95.46 507.57 182.22 501 259.04 499.72 214.22 485.62 95.46 444.72 95.46 478.28 346.85 519.17 346.85 531.7 249.96 531.64 205.69 536.25 242.08 549.8 346.85 590.77 346.85 624.33 95.46 583.43 95.46"
								fill="currentColor"
							/>
							<polygon
								points="268.77 95.46 268.77 181.91 272.86 239.96 260.72 187.25 228.5 95.46 192.15 95.46 192.15 346.85 233.06 346.85 233.06 265.42 233.01 265.42 228.6 200.31 235.72 232.57 275.84 346.85 309.68 346.85 309.68 95.46 268.77 95.46"
								fill="currentColor"
							/>
							<polygon
								points="377.59 241.61 416.95 241.61 416.95 200.71 377.59 200.71 377.59 136.37 421.47 136.37 428.23 95.46 336.69 95.46 336.69 346.86 421.47 346.86 428.23 305.95 377.59 305.95 377.59 241.61"
								fill="currentColor"
							/>
							<rect
								x="11.14"
								y="345.75"
								width="111.42"
								height="95.25"
								fill="#e8f3f3"
							/>
							<polygon
								points="131.82 61.27 133.95 0 0 0 11.19 317.63 122.79 317.63 124.94 259.53 71.66 259.53 71.66 177.91 127.71 177.91 129.6 124.27 72.48 124.27 72.48 61.27 131.82 61.27"
								fill="#e8f3f3"
							/>
						</svg>
					</a>
				</div>
				<div className="nav-bar__content-items">
					<div className="nav-bar__hot-topic-links">
						{hotTopicsLinks?.map(({ reference, displayTitle }, index) => {
							if (!displayTitle || !reference?.uri) return null;

							const title = striptags(displayTitle);
							const { uri } = reference;

							structuredDataNames.push(title.toUpperCase());
							structuredDataUrls.push(`https://${APP_HOSTNAME}${uri}`);

							return (
								<a
									className="nav-bar__hot-topic-link"
									data-analytics-linklocation="hot-topics-banner"
									data-analytics-linkname={createAnalyticsString(title)}
									data-hook="nav-bar__hot-topic-link"
									key={`nav-bar__hot-topic-link-${index}`}
									href={uri}
								>
									{title}
								</a>
							);
						})}
					</div>
					<div className="nav-bar__nav-bar-icons">
						<NavBarIcons />
					</div>
				</div>
			</div>
			{edition === 'us' && path === '/' ? (
				<StructuredData
					data={{
						'@context': 'https://schema.org',
						'@type': 'SiteNavigationElement',
						name: [structuredDataNames],
						url: [structuredDataUrls]
					}}
				/>
			) : null}
		</nav>
	);
};

NavBar.displayName = 'NavBar';

export default NavBar;
