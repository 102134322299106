import React, { createContext, useContext } from 'react';
import { object, element, func } from 'prop-types';
import { flags } from './paramToggleFlags';

const ParamToggleContext = createContext(flags);

export const ParamToggle = ParamToggleContext;

export const ParamToggleConsumer = ({ children }) => (
	<ParamToggle.Consumer>{children}</ParamToggle.Consumer>
);

ParamToggleConsumer.displayName = 'ParamToggleConsumer';

ParamToggleConsumer.propTypes = {
	children: func.isRequired
};
/**
 * @function useParamToggleContext
 * @return {Object}
 */
export const useParamToggleContext = () => useContext(ParamToggleContext);

/**
 * @param {Object} props
 * @param {Object} props.queryParams - The queryParams from the server req.query
 * @returns {React.ReactElement} props.children - React children components
 */
export const ParamToggleProvider = ({ queryParams, children }) => {
	return <ParamToggle.Provider value={queryParams}>{children}</ParamToggle.Provider>;
};

ParamToggleProvider.propTypes = {
	queryParams: object.isRequired,
	children: element.isRequired
};

ParamToggleProvider.displayName = 'ParamToggleProvider';
