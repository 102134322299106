import { onError } from '@apollo/client/link/error';

export default (logger) => {
	const log = logger ? logger.error : console.error;

	return onError(({ graphQLErrors, networkError, response, operation }) => {
		if (operation && operation.operationName) {
			log(`[GraphQL operation]: ${operation.operationName}`);
		}
		if (graphQLErrors) {
			graphQLErrors.forEach(({ message, locations, path, extensions }) => {
				log(`[GraphQL error]: Message: ${message}`);
				locations && log(`[GraphQL location]: ${locations}`);
				path && log(`[GraphQL path]: ${path}`);
				if (extensions && extensions.code) {
					log(`[GraphQL extension code]: ${extensions.code}`);
					// if the role is not authorized we redirect to no auth page
					if (extensions.code === 'FORBIDDEN' && typeof window !== 'undefined') {
						window.location.href = '/notauthorized';
					}
					// if token is expired or missing we refresh to get new token
					if (extensions.code === 'UNAUTHENTICATED' && typeof window !== 'undefined') {
						window.location.reload();
					}
				}
			});
		}

		if (networkError) {
			log(`[Network error]: ${networkError}`);
		}
		response && logger.verbose(response);
	});
};
