import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import 'svgxuse';
import { hydrate } from 'react-dom';
import { loadableReady } from '@loadable/component';
import { shape, element, bool } from 'prop-types';
import Page from '../components/layout/page/Page';
import getClient from '@/graphql/client/client';
import { NODE_ENV } from '@/config/config';
import { getFlagParamValues } from '@/components/param-toggle/paramToggleFlags';

/**
 * @function registerServiceWorker
 * @description Registers the service worker generated by workbox
 */
const registerServiceWorker = () => {
	// Set up Service worker
	if ('serviceWorker' in navigator && NODE_ENV === 'production') {
		window.addEventListener('load', () => {
			navigator.serviceWorker.register(`/dist/service-worker.js`, { scope: '/' });
		});
	}
};

/**
 * @function _hydrate
 * @description A wrapper around hydrate that adds the common Template
 * @param {React.ReactElement} Component - The component that we want to hydrate
 * @param {Object} [opts]
 * @param {*} [opts.Template] - The page Template, defaults to the common page
 * @param {Object} [opts.PageProps] additional pageprops
 */
const _hydrate = (Component, opts) => {
	// Set default options similar to server-side renderer.js
	const defaultOpts = {
		Template: Page,
		PageProps: {}
	};

	// Merge defaults with passed parameters
	const { Template, PageProps } = Object.assign(defaultOpts, opts);
	const queryParams = getFlagParamValues();
	const { SERVICE_WORKER_ENABLED } = queryParams;

	SERVICE_WORKER_ENABLED && registerServiceWorker();

	const data = window.__PAGE_DATA__;
	const client = getClient(data?.userToken);

	hydrate(
		<HelmetProvider>
			<Template pageData={data} client={client} queryParams={queryParams} {...PageProps}>
				{Component}
			</Template>
		</HelmetProvider>,
		document.getElementById('root')
	);
};

/**
 * @function hydrateWrap
 * @description A wrapper around hydrate that hydrates when ready
 * @param {React.ReactElement} Component - The component that we want to hydrate
 * @param {Object} [opts]
 * @param {React.ReactElement} [opts.Template] - The page Template, defaults to the common page
 * @param {Object} [opts.PageProps] additional pageprops
 */
const hydrateWrap = (Component, opts) => {
	loadableReady(() => _hydrate(Component, opts)).catch(() => _hydrate(Component, opts));
};

hydrateWrap.propTypes = {
	Component: element,
	opts: shape({
		Template: element,
		PageProps: shape({
			hasContainerClass: bool,
			hasTopBannerAd: bool
		})
	})
};

export default hydrateWrap;
