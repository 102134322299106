import React, { memo } from 'react';
import { string } from 'prop-types';
import { PageConsumer } from '@/components/layout/page/PageContext';

/**
 * @function Translation
 * @param {Object} props
 * @param {String} props.word
 * @return {React.ReactElement} The word you want translated
 */
const Translate = ({ word }) => {
	return typeof word === 'string' ? (
		<PageConsumer>
			{({ translations }) => translations[word.toUpperCase()] || word}
		</PageConsumer>
	) : (
		word
	);
};

Translate.propTypes = {
	word: string.isRequired
};

Translate.displayName = 'Translate';

export default memo(Translate);
