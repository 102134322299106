import parse from 'html-react-parser';
import { oneOfType, object, string } from 'prop-types';

/**
 * @function parseHtml
 * @description parses text to handle special characters
 * @param {String|Object} [text] the text to parse HTML elements
 * @returns {String|Object|Null} the parsed text result
 */
const parseHtml = (text) => {
	if (!text) return null;

	// TOOD: Only adding a check for <3 which is causing issues.
	// We can extend this to other characters and write a better regex if we see more issues
	const cleanedText = typeof text === 'string' ? text.replace('<3', '&lt;3') : text;

	return parse(cleanedText);
};

parseHtml.propTypes = {
	text: oneOfType([string, object])
};

parseHtml.displayName = 'parseHtml';

export default parseHtml;
