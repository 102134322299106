import React from 'react';
import striptags from 'striptags';
import createAnalyticsString from '@/utils/createAnalyticsString';
import { usePageContext } from '@/components/layout/page/PageContext';
import SvgImage from '@/components/icons/SvgImage';
import peacockSvg from '@/images/peacock-large-black.svg';
import universalSvg from '@/images/universal-plus.svg';
import { useNavContext } from '@/components/layout/navigation/NavContext';
import Translate from '@/components/i18n/Translate';

/**
 * @function FooterLinks
 * @description provides dynamic links for footer
 * @requires NavContext
 * @returns {React.ReactElement}
 */
const Featured = () => {
	const { intlLinks } = usePageContext();
	const { nav } = useNavContext();
	const links = nav?.footerText?.links || [];

	const peacockLink = intlLinks.PEACOCK_OVERLAY;
	const universalLink = intlLinks.UNIVERSAL_PLUS;

	return links.length > 0 ? (
		<div className="main-menu__section">
			<span className="main-menu__section-title">
				<Translate word="FEATURED" />
			</span>
			<div className="main-menu__section-columns">
				{links.map(({ reference, displayTitle }, index) => {
					const title = striptags(displayTitle);
					const uri = reference?.uri;

					return uri && title ? (
						<a
							className="main-menu__section-item"
							data-analytics-linklocation="menu-overlay-footer"
							data-analytics-linkname={createAnalyticsString(title)}
							data-hook="footer-links__link"
							href={uri}
							key={`footer-link-${index}`}
						>
							{title}
						</a>
					) : null;
				})}
				{universalLink && (
					<a
						className="main-menu__section-item"
						data-analytics-linklocation="menu-overlay-nav"
						data-analytics-linkname="universal-plus-logo"
						href={universalLink}
						rel="noopener noreferrer"
						target="_blank"
					>
						<SvgImage
							className="mainmenu-categories__universal-svg"
							image={universalSvg}
						/>
					</a>
				)}
				{peacockLink && (
					<a
						className="main-menu__section-item"
						data-analytics-linklocation="menu-overlay-nav"
						data-analytics-linkname="peacock-logo"
						href={peacockLink}
						rel="noopener noreferrer"
						target="_blank"
					>
						<SvgImage className="mainmenu-categories__peacock-svg" image={peacockSvg} />
					</a>
				)}
			</div>
		</div>
	) : null;
};

Featured.displayName = 'FooterLinks';

export default Featured;
