import React, { createContext, useContext } from 'react';
import { any } from 'prop-types';

// Initialize a new context
export const PageContext = createContext({
	translations: {},
	intlLinks: {},
	edition: 'us',
	lang: 'EN',
	path: '/news',
	uppercaseEdition: 'US',
	identityAuthentication: null,
	featureToggles: {
		TABOOLA_THEME_ENABLED: true,
		ONE_TRUST_INTEGRITY: '',
		HAS_ONETRUST_TCF_STUB: false,
		NEW_INTEGRITY_FEATURE: false,
		TABOOLA_STORIES_ENABLED: true,
		CONTEXTUAL_WIDGET: false,
		NAV_AB_TEST: true,
		GAMMA_PLAYER: false,
		MOBILE_SUB_NAV_ENABLED: false,
		TABOOLA_GALLERY_CONTENT_RECS_ENABLED: false
	}
});

/**
 * @function usePageContext
 * @description provides PageContext data when instantiated
 * @returns {Object}
 */
export const usePageContext = () => useContext(PageContext);

/**
 * @function useFeatureToggleContext
 * @description provides the object "featureToggles" from PageContext
 * @returns {Object}
 */
export const useFeatureToggleContext = () => usePageContext()?.featureToggles;

/**
 * @function PageConsumer
 * @description Classic Consumer, context passed as a render prop
 * @param {Object} props
 * @param {*} props.children
 * @return {React.ReactElement}
 */
export const PageConsumer = ({ children }) => (
	<PageContext.Consumer>{children}</PageContext.Consumer>
);

PageConsumer.propTypes = {
	children: any.isRequired
};

PageConsumer.displayName = 'PageConsumer';
