/**
 * @function breakpointType
 * @param {Number} width the window width to compare
 * @returns {String} the current breakpoint device type
 */
export default (width) => {
	const isTablet = width >= 758 && width < 1024;
	const isDesktop = width >= 1024;
	return isDesktop ? 'desktop' : isTablet ? 'tablet' : 'mobile';
};
