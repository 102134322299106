import React from 'react';
import { Helmet } from 'react-helmet-async';

/**
 * @function ThirdPartyScripts
 * @description Third Party video analytics scripts for the Gamma Player
 * @returns {React.ReactElement}
 */
const ThirdPartyScripts = () => {
	return (
		<Helmet>
			<script type="text/plain" src="/nielsen/Nielsen.js" className="optanon-category-4" />
			<script type="text/javascript" src="/adobe/VisitorAPI.js" />
			<script type="text/javascript" src="/adobe/MediaSDK.js" />
			<script type="text/javascript" src="/adobe/AppMeasurement.js" />
		</Helmet>
	);
};

ThirdPartyScripts.displayName = 'ThirdPartyScripts';

export default ThirdPartyScripts;
