import React from 'react';
import { object, string } from 'prop-types';

const getRelativeSrc = (src) => {
	try {
		const url = new URL(src);
		return src.replace(url.origin, '');
	} catch {
		return src;
	}
};

/**
 * @function SvgImage
 * @description Adds SVG image from sprite
 * @param {Object} props
 * @param {Object} props.image - imported from .svg file via external-svg-spite-loader
 * @param {String} [props.className]
 * @returns {Object} JSX
 */
const SvgImage = ({ image, className }) => {
	const src =
		typeof URL !== 'undefined' && module.hot ? getRelativeSrc(image.symbol) : image.symbol;
	const viewBox =
		image?.viewBox && image?.viewBox !== 'undefined' ? { viewBox: image?.viewBox } : {};
	return (
		<svg className={className} {...viewBox} title={image.title} role="img">
			<use xlinkHref={src} suppressHydrationWarning />
		</svg>
	);
};

SvgImage.propTypes = {
	image: object.isRequired,
	className: string
};

SvgImage.displayName = 'SvgImage';

export default SvgImage;
