import React from 'react';
import striptags from 'striptags';
import createAnalyticsString from '@/utils/createAnalyticsString';
import { usePageContext } from '@/components/layout/page/PageContext';
import Translate from '@/components/i18n/Translate';
import intlUrl from '@/components/i18n/intlUrl';
import { useNavContext } from '@/components/layout/navigation/NavContext';
import './MainMenuCategories.scss';

/**
 * @function MainMenuCategories
 * @description Provides category links for the Main Menu Overlay
 * @returns {React.ReactElement}
 */
const MainMenuCategories = () => {
	const { edition, intlLinks } = usePageContext();
	const { nav } = useNavContext();
	const links = nav?.categories?.links || [];

	const defaultCategoryLinks = [
		{
			displayTitle: 'Top Stories',
			reference: { uri: intlUrl(edition, '/news') }
		},
		{
			displayTitle: 'E! Shows',
			reference: { uri: intlUrl(edition, '/shows') }
		},
		{
			displayTitle: 'Watch',
			reference: { uri: intlLinks.WATCH_LIVE }
		}
	];

	const categories = links.length > 0 ? links : defaultCategoryLinks;

	return (
		<div className="main-menu__section">
			<span className="main-menu__section-title">
				<Translate word="SECTIONS" />
			</span>
			<div className="main-menu__section-columns">
				{categories.map(({ displayTitle, reference }, index) => {
					const title = striptags(displayTitle);

					return title && reference?.uri ? (
						<a
							key={`mainmenu-category-${index}`}
							className="main-menu__section-item"
							data-analytics-linklocation="menu-overlay-nav"
							data-analytics-linkname={createAnalyticsString(title)}
							data-hook="main-menu-category"
							href={reference?.uri}
						>
							{title}
						</a>
					) : null;
				})}
			</div>
		</div>
	);
};

MainMenuCategories.displayName = 'MainMenuCategories';

export default MainMenuCategories;
