const adChoicesLink = {
	link: 'AD_CHOICES',
	text: 'AD_CHOICES',
	targetEditions: {
		br: true,
		ca: true,
		mx: true,
		us: true
	}
};

const privacyLink = {
	link: 'PRIVACY_POLICY',
	text: 'PRIVACY_POLICY'
};

const californiaNoticeLink = {
	link:
		'https://www.nbcuniversal.com/privacy/california-consumer-privacy-act?intake=E!_Entertainment',
	text: 'CA Notice',
	targetEditions: { us: true }
};

const termsOfServiceLink = {
	link: 'TERMS_OF_SERVICE',
	text: 'TERMS_OF_SERVICE',
	targetEditions: {
		us: true,
		ca: true,
		uk: true,
		au: true,
		ap: true,
		de: true,
		fr: true
	}
};

export default () => {
	const cookieLink = {
		className: 'ot-sdk-show-settings',
		text: 'COOKIE_SETTINGS_ONE_TRUST',
		hasPrivacyIcon: true,
		targetEditions: {
			ap: true,
			ar: true,
			au: true,
			br: true,
			ca: true,
			co: true,
			de: true,
			fr: true,
			mx: true,
			uk: true,
			us: true,
			ve: true
		}
	};

	return [adChoicesLink, privacyLink, cookieLink, californiaNoticeLink, termsOfServiceLink];
};
