import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink, split } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { setContext } from '@apollo/client/link/context';
import { sha256 } from 'crypto-hash';

import {
	PUBLIC_GRAPHQL_URL,
	IS_PREVIEW_MODE,
	APP_ENV,
	APP_VERSION,
	IS_LOCAL
} from '@/config/config';

import getErrorLink from './errorLink';

const defaultOptions = {
	watchQuery: {
		errorPolicy: 'ignore'
	},
	query: {
		errorPolicy: 'all'
	}
};

// Sets up the link for Automatic Persisted Queries
const persistedQueryLink = (useGet) =>
	createPersistedQueryLink({
		sha256,
		useGETForHashedQueries: useGet
	});

/**
 * @function authLink
 * @description provides a wrapper for httpLink with an authorization context
 * usage: authLink(token).concat(httpLink)
 * @param {String} [token]
 * @returns {Object}
 */
const authLink = (token) =>
	setContext((_, { headers }) => ({
		headers: {
			...headers,
			Authorization: `Bearer ${token}`
		}
	}));

/**
 * @function getHttpLink
 * @description returns an HTTP with or without Authorization context
 * @param {String} token
 * @returns {Object}
 */
const getHttpLink = (token) => {
	const httpLink = createHttpLink({
		uri: PUBLIC_GRAPHQL_URL,
		credentials: 'same-origin'
	});

	return token && IS_PREVIEW_MODE ? authLink(token).concat(httpLink) : httpLink;
};

/**
 * @function getBatchHttpLink
 * @description returns a batch HTTP with or without Authorization context
 * @param {String} token
 * @returns {Object}
 */
const getBatchHttpLink = (token) => {
	const batchHttpLink = new BatchHttpLink({
		uri: PUBLIC_GRAPHQL_URL,
		credentials: 'same-origin',
		headers: { batch: 'true ' }
	});

	return token && IS_PREVIEW_MODE ? authLink(token).concat(batchHttpLink) : batchHttpLink;
};

export default (token) => {
	return new ApolloClient({
		link: ApolloLink.from([
			getErrorLink(),
			split(
				(operation) => operation.getContext().noBatch === true,
				persistedQueryLink(true).concat(getHttpLink(token)),
				persistedQueryLink(false).concat(getBatchHttpLink(token))
			)
		]),
		cache: new InMemoryCache().restore(window.__APOLLO_STATE__),
		defaultOptions,
		name: `eol-web${IS_PREVIEW_MODE ? '-(preview)__' : '__'}${IS_LOCAL ? 'local' : APP_ENV}`,
		version: APP_VERSION,
		assumeImmutableResults: true
	});
};
