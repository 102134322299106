import React from 'react';
import { object, string } from 'prop-types';
import infoLinks from './infoLinks';
import createAnalyticsString from '@/utils/createAnalyticsString';
import Translate from '@/components/i18n/Translate';
import { usePageContext } from '@/components/layout/page/PageContext';
import './MoreE.scss';

/**
 * @function applyTarget
 * @param {String} link
 * @param {String} attribute
 * @returns {String|Undefined}
 */
const applyTarget = (link, attribute) => {
	const isInternal =
		!link ||
		link.charAt(0) === '/' ||
		link.includes('eonline.com') ||
		link.includes('mailto:') ||
		link === '#';

	if (attribute === 'target') {
		return isInternal ? undefined : '_blank';
	} else {
		return isInternal ? undefined : 'noopener noreferrer';
	}
};

/**
 * @function Button
 * @description returns a <button> or <a> with props and translated text
 * @param {Object} props
 * @param {Object} props.children - text being wrapped by button or anchor tag
 * @param {String} [props.href] - if passed in, Button will return <a>
 * @returns {React.ReactElement}
 */
const Button = ({ children, href, ...props }) => {
	return href ? (
		<a href={href} {...props}>
			{children}
		</a>
	) : (
		<button {...props}>{children}</button>
	);
};

Button.displayName = 'Button';

Button.propTypes = {
	children: object,
	href: string
};

/**
 * @function GlobalFooter
 * @returns {React.ReactElement}
 */
const MoreE = () => {
	const { edition, intlLinks } = usePageContext();
	const newsTipLink = 'mailto:enewstipline@nbcuni.com?subject=News Tip';
	const advertiseLink =
		'https://together.nbcuni.com/advertise/?utm_source=e_online&utm_medium=referral&utm_campaign=property_ad_pages&utm_content=header';
	const licensingLink = 'https://info.wrightsmedia.com/e-online-licensing';

	return intlLinks ? (
		<div className="main-menu__section" data-hook="info-links">
			<span className="main-menu__section-title">
				<Translate word="MORE_E" />
			</span>
			<div className="main-menu__section-columns">
				{infoLinks.map(({ link, text, targetEditions }, index) => {
					/* "showInfoLink" determines if "targetEditions"
								is absent or if there's a matching target edition */
					const showInfoLink = !targetEditions || targetEditions[edition];

					return showInfoLink ? (
						<a
							key={`infolink-${index}`}
							className="main-menu__section-item"
							data-analytics-linklocation="menu-overlay-footer"
							data-analytics-linkname={createAnalyticsString(text)}
							href={intlLinks[link] || link}
							target={applyTarget(intlLinks[link] || link, 'target')}
							rel={applyTarget(intlLinks[link] || link, 'rel')}
						>
							<Translate word={text} />
						</a>
					) : null;
				})}
			</div>
			{edition === 'us' && (
				<div className="global-overlay__buttons">
					<button
						className="button is-primary is-outlined global-overlay__button"
						onClick={() => window.open(advertiseLink, '_self')}
					>
						Advertise With Us
					</button>
					<button
						className="button is-primary is-outlined global-overlay__button"
						onClick={() => window.open(licensingLink, '_self')}
					>
						Licensing & Reuse of Content
					</button>
					<button
						className="button is-primary is-outlined global-overlay__button"
						onClick={() => window.open(newsTipLink, '_self')}
					>
						submit a news tip
					</button>
				</div>
			)}
		</div>
	) : null;
};

MoreE.displayName = 'MoreE';

export default MoreE;
