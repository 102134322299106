import React, { memo } from 'react';
import MainMenuHeaderIcons from './MainMenuHeaderIcons';
import { usePageContext } from '@/components/layout/page/PageContext.jsx';
import SvgImage from '@/components/icons/SvgImage';
import LogoSvg from '@/images/elogo/ENewsLogo.svg';
import intlUrl from '@/components/i18n/intlUrl';
import './MainMenuHeader.scss';

/**
 * @function MainMenuHeader
 * @description Provides the header portion of Main Menu Overlay
 * @returns {React.ReactComponentElement}
 */
const MainMenuHeader = () => {
	const { edition } = usePageContext();
	const homeLink = intlUrl(edition, '/');
	return (
		<nav
			className="main-menu-header main-menu-header__overlay-open"
			data-hook="main-menu-header"
		>
			<div className="main-menu-header__container container columns is-mobile">
				{/* E! Logo */}
				<div className="column is-1">
					<a
						className="main-menu-header__elogo-container main-menu-header__elogo-container-hover"
						data-analytics-linklocation="menu-overlay-header"
						data-analytics-linkname="e-logo"
						href={homeLink}
					>
						<SvgImage className="main-menu-header__elogo" image={LogoSvg} />
					</a>
				</div>

				<div className="column is-narrow">
					<MainMenuHeaderIcons />
				</div>
			</div>
		</nav>
	);
};

MainMenuHeader.displayName = 'MainMenuHeader';

export default memo(MainMenuHeader);
