import React, { useState, useEffect, useRef } from 'react';
import { useNavContext } from '@/components/layout/navigation/NavContext';
import striptags from 'striptags';
import { APP_HOSTNAME } from '@/config/config';
import createAnalyticsString from '@/utils/createAnalyticsString';
import './MobileSubnav.scss';

const getURLPath = (inputURL) => {
	let url;

	try {
		url = new URL(inputURL);
	} catch (error) {
		return inputURL;
	}

	if (url.protocol && url.host) {
		return url.pathname;
	}

	return inputURL;
};

const MobileSubnav = () => {
	const { nav } = useNavContext();

	// states
	const [isVisible, setIsVisible] = useState(true);
	const [scrollDirection, setScrollDirection] = useState('none');
	const [currentPath, setCurrentPath] = useState(null);

	const lastScrollPositionRef = useRef(0);
	const isVisibleRef = useRef(isVisible);

	const structuredDataNames = [];
	const structuredDataUrls = [];
	const hotTopicsLinks = nav?.hotTopics?.links || [];

	const handleScroll = () => {
		const currentScrollPosition = window.scrollY;

		if (
			/* ignore anything below 0 - some
			browsers, like safari, go in to the
			negative scroll values due to "rubber band"
			effect */
			currentScrollPosition > 0 &&
			lastScrollPositionRef.current > 0 &&
			currentScrollPosition > lastScrollPositionRef.current
		) {
			setScrollDirection('down');
		} else {
			setScrollDirection('up');
		}

		lastScrollPositionRef.current = currentScrollPosition;
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	useEffect(() => {
		let timeoutId;
		const getMobileSubnavEvent = () =>
			new CustomEvent('mobileSubnavEvent', {
				detail: {
					isVisible: isVisibleRef.current
				}
			});

		if (scrollDirection === 'down') {
			timeoutId = setTimeout(() => {
				setIsVisible(false);
				isVisibleRef.current = false;
				document.dispatchEvent(getMobileSubnavEvent());
			}, 1000);
		} else {
			setIsVisible(true);
			isVisibleRef.current = true;
			document.dispatchEvent(getMobileSubnavEvent());
		}

		return () => {
			clearTimeout(timeoutId);
		};
	}, [scrollDirection]);

	useEffect(() => {
		setCurrentPath(window.location.pathname);
	}, []);

	return (
		<div className={`mobile-subnav${isVisible ? '' : ' mobile-subnav--collapse'}`}>
			<span className="mobile-subnav__gradient-wrapper">
				<div className="mobile-subnav__gradient-1"></div>
			</span>
			<span className="mobile-subnav__hot-topic-links">
				{hotTopicsLinks?.map(({ reference, displayTitle }, index) => {
					if (!displayTitle || !reference?.uri) return null;

					const title = striptags(displayTitle);
					const { uri } = reference;

					structuredDataNames.push(title.toUpperCase());
					structuredDataUrls.push(`https://${APP_HOSTNAME}${uri}`);

					return (
						<a
							className={`mobile-subnav__hot-topic-link${
								currentPath === getURLPath(uri)
									? ' mobile-subnav__hot-topic-link--selected'
									: ''
							}`}
							data-analytics-linklocation="hot-topics-banner"
							data-analytics-linkname={createAnalyticsString(title)}
							data-hook="mobile-subnav__hot-topic-link"
							key={`mobile-subnav__hot-topic-link-${index}`}
							href={uri}
						>
							{title}
						</a>
					);
				})}
			</span>
			<div className="mobile-subnav__gradient-2"></div>
		</div>
	);
};

MobileSubnav.displayName = 'MobileSubnav';

export default MobileSubnav;
