import React, { forwardRef } from 'react';
import { useParamToggleContext } from '@/components/param-toggle/ParamToggleContext';
import { usePageContext } from '@/components/layout/page/PageContext';
import { any, string, func } from 'prop-types';

/**
 * @function GatedLink
 * @description creates a restriction on a link on an E! Insider (Identity) page
 * If user is logged in, the link works as normal
 * If a user is not logged in, the user is instead prompted by a login window
 * @param {String} href
 * @param {Object} props
 * @return {React.ReactElement}
 */
const GatedLink = forwardRef(({ href, children, rel, onClick, ...props }, ref) => {
	const { IDENTITY_ENABLED_PARAM } = useParamToggleContext();
	const { isGatedPage, identityAuthentication } = usePageContext();

	// detect if this is a gated page type (flagged as an E! Insider page)
	const isGated = isGatedPage && IDENTITY_ENABLED_PARAM;
	// if it's gated, disable links until a user autheticated state is detected
	const authenticated = !isGated || identityAuthentication === 'authenticated';

	const handleClick = (e) => {
		e.preventDefault();
		window.nbc.identity.launch({
			referringPage: 'e-insider',
			registrationReferrer: 'None',
			defaultPage: 'createProfile'
		});
	};

	return (
		<a
			href={authenticated ? href : '#'}
			onClick={authenticated ? onClick : handleClick}
			ref={ref}
			rel={rel || 'noopener noreferrer'}
			{...props}
		>
			{children}
		</a>
	);
});

GatedLink.defaulProps = {
	href: '',
	rel: '',
	onClick: null,
	children: null
};

GatedLink.propTypes = {
	href: string,
	rel: string,
	onClick: func,
	children: any
};

GatedLink.displayName = 'GatedLink';

export default GatedLink;
