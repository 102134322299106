import { publishSlotCallbacks } from './mps';

export default (gptLoadedCB) => {
	if (typeof window === 'undefined') return;
	/* ====| MPS Queue / GPT loaded |==== */
	window.mps = window.mps || {};
	mps._queue = mps._queue || {};
	mps._queue.gptloaded = mps._queue.gptloaded || [];
	mps._queue.adload = mps._queue.adload || [];
	mps._queue.adload = mps._queue.adload || [];
	mps._queue.refreshads = mps._queue.refreshads || [];

	mps._queue.gptloaded.push(() => {
		// console.log('***** GPT INITIALIZED *****');
		// GPT Loaded CB for responsive ads
		gptLoadedCB();

		/* =====| MPS CALLBACK FOR EACH AD LOAD |====== */
		mps._queue.adload.push(function (eo) {
			// If success or error callback exists for slot, will execute
			publishSlotCallbacks(eo._mps._slot, eo);
		});

		/* =====| MPS CALLBACK FOR REFRESHED ADS LOADED |====== */
		mps._queue.refreshads.push((slotvars) => {
			console.log('**** ADS REFRESHED ON PAGE *****', slotvars);
		});
	});
};
