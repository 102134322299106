import loadScript from '@/utils/loadScript';
import { NBCU_CHECKOUT_URL } from '@/config/config';

const networkBrand = 'nbcu-eonline';
const theme = 'EOnline';
const popoverRenewalTimeUnit = 'days';
const popoverRenewalTimeAmount = 60;

const externalLinks = {
	customerService: '/support/uc/#customer-service',
	returnsPolicy: '/support/uc/#shipping-returns-policy',
	faq: '/support/uc/#faqs',
	termsAndConditions: 'https://www.eonline.com/support/uc#terms-of-service',
	privacyPolicy: 'https://www.nbcuniversal.com/privacy?intake=E!_Entertainment'
};

/**
 * @function loadCheckoutScript
 * @param {String} lang - from PageContext
 * @param {String} uppercaseEdition - from PageContext
 * @param {Function} [setCheckoutResponse] - state passed to PageContext to update load state; used in articles
 */
export default (lang, uppercaseEdition, setCheckoutResponse = null) => {
	const checkoutConfig = {
		externalLinks,
		networkBrand,
		theme,
		popoverRenewalTimeUnit,
		popoverRenewalTimeAmount,

		// creates a string with language and edition, i.e. en-US
		lang: `${lang?.toLowerCase()}-${uppercaseEdition}`
	};

	loadScript({
		defer: true,
		src: NBCU_CHECKOUT_URL,
		type: 'module',
		onLoad: () => {
			window?.uscWidget &&
				window.uscWidget
					.init(checkoutConfig)
					.then(() => {
						if (setCheckoutResponse) setCheckoutResponse({ loaded: true });
					})
					.catch((err) => {
						if (setCheckoutResponse) setCheckoutResponse({ loaded: true, err });
					});
		}
	});
};
