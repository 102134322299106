import React, { useState, useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import { Helmet } from 'react-helmet-async';
import { shape, element, string, any, object, bool } from 'prop-types';
import { PageContext } from './PageContext';
import DockableVideoProviderWrap from '@/components/video/dockable-video/DockableVideoProviderWrap';
import { ParamToggleProvider } from '@/components/param-toggle/ParamToggleContext';
import { MpsConfig, MpsFooter } from '@/components/mps/MpsConfig';
import HeadMeta from '../HeadMeta';
import Navigation from '../navigation/Navigation';
import GlobalFooter from '@/components/layout/navigation/footer/GlobalFooter';
import TopBannerAd from '@/components/ads/TopBannerAd';
import { ResizePageObserver } from './ResizePageObserver';
import AdobeLaunch from '@/components/analytics/AdobeLaunch';
import { OneTrustProviderWrap } from '@/components/onetrust/OneTrust';
import Identity from '@/components/identity/Identity';
import loadCheckoutScript from '@/components/checkout/loadCheckoutScript';
import ThirdPartyScripts from '@/components/video/ThirdPartyScripts';
import '@/scss/styles.scss';

/**
 * @function Page
 * @description The Common Page component that's SSR & clientside rendered
 * @param {Object} props
 * @param {React.ReactElement} props.children
 * @param {Object<string,string>} props.pageData Static page data
 * @param {*} props.client The apollo client based on if its ss or cs
 * @param {Object} [props.queryParams] The queryParams in the user request url
 * @param {Boolean} props.hasContainerClass Control if {children} is wrapped in a container element
 * @param {Boolean} props.hasTopBannerAd Control if it has a topBanner for the container class
 * @return {React.ReactElement}
 */
const Page = ({ children, pageData, client, queryParams, hasContainerClass, hasTopBannerAd }) => {
	// states
	const [identityAuthentication, setAuthentication] = useState(null);
	const [checkoutResponse, setCheckoutResponse] = useState({ loaded: false });

	const isDetail = ['news detail', 'photo detail', 'video detail'].includes(pageData.mpsPageType);

	useEffect(() => {
		if (typeof window !== 'undefined' && !pageData.isAmp) {
			const callback = pageData.articleId ? setCheckoutResponse : null;
			loadCheckoutScript(pageData.lang, pageData.uppercaseEdition, callback);
		}
	}, [pageData]);

	return (
		<ApolloProvider client={client}>
			<PageContext.Provider value={{ ...pageData, identityAuthentication, checkoutResponse }}>
				<ParamToggleProvider queryParams={queryParams}>
					<ResizePageObserver>
						<MpsConfig />
						<HeadMeta isDetail={isDetail} />
						<Navigation />
						<OneTrustProviderWrap>
							{pageData.GAMMA_PLAYER_ENABLED ||
							pageData.featureToggles.GAMMA_PLAYER ? (
								<ThirdPartyScripts />
							) : null}
							<DockableVideoProviderWrap>
								<div className="theme-wrap">
									{hasContainerClass ? (
										<main className="container">
											{hasTopBannerAd && <TopBannerAd />}
											{children}
										</main>
									) : (
										children
									)}
								</div>
							</DockableVideoProviderWrap>
						</OneTrustProviderWrap>
						<MpsFooter />
						<footer className="page-footer">
							<GlobalFooter />
						</footer>

						<AdobeLaunch />
						<Identity setAuthentication={setAuthentication} />
					</ResizePageObserver>
				</ParamToggleProvider>
			</PageContext.Provider>
			{/* override default Helmet options */}
			<Helmet encodeSpecialCharacters={false} />
		</ApolloProvider>
	);
};

Page.defaultProps = {
	pageData: {},
	queryParams: {},
	hasContainerClass: true,
	hasTopBannerAd: true
};

Page.propTypes = {
	children: element.isRequired,
	pageData: shape({
		edition: string.isRequired
	}),
	client: any,
	queryParams: object,
	hasContainerClass: bool,
	hasTopBannerAd: bool
};

Page.displayName = 'Page';

export default Page;
