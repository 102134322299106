import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import throttle from 'lodash-es/throttle';
import MainMenu from './main-menu/MainMenu';
import NotificationBar from '@/components/layout/navigation/banners/notification-bar/NotificationBar';
import NavBar from '@/components/layout/navigation/banners/navbar/NavBar';
import NAV from '@/graphql/queries/NAV';
import { NavContext } from './NavContext';
import { usePageContext, useFeatureToggleContext } from '@/components/layout/page/PageContext';
import { IS_PREVIEW_MODE } from '@/config/config';
import MobileSubnav from '@/components/mobile-subnav/MobileSubnav';
import { useParamToggleContext } from '@/components/param-toggle/ParamToggleContext';
import './Navigation.scss';
/**
 * @class Navigation
 * @description Entirety of site navigation wrapping all standard pages. AMP pages are excluded and have a custom nav
 * @returns {React.ReactElement} JSX
 */
const Navigation = () => {
	const { edition, uppercaseEdition, useLastSavedNav } = usePageContext();
	const { MOBILE_SUB_NAV_PARAM } = useParamToggleContext();
	const { MOBILE_SUB_NAV_ENABLED } = useFeatureToggleContext();

	const variables = { id: uppercaseEdition };
	const enableMobileSubnav = MOBILE_SUB_NAV_PARAM || MOBILE_SUB_NAV_ENABLED;

	/*
	 * Query last saved Navigation data instead of last published when appropriate
	 * This will only work on the Preview servers
	 * useLastSavedNav is set from eol-cms Preview
	 */
	if (IS_PREVIEW_MODE && useLastSavedNav) variables.source = 'SAVED';

	// send Navigation query and receive response
	const { data, error } = useQuery(NAV, { variables, context: { noBatch: true } });

	if (error) console.error(`error in Nav query: ${error.message}`);

	// initialize
	const [renderedMenu, updateRenderedMenu] = useState(false);
	const [isCollapsed, updateIsCollapsed] = useState(false);
	const [showMenu, updateShowMenu] = useState(false);
	const [seeEditions, setSeeEditions] = useState(false);

	// set up helper methods
	const isCollapsedRef = useRef(isCollapsed);
	const toggleSeeEditions = () => setSeeEditions(!seeEditions);
	const toggleCollapsed = () => {
		const newState = !isCollapsedRef.current;

		isCollapsedRef.current = newState;
		updateIsCollapsed(newState);
	};

	const toggleShowMenu = () => updateShowMenu(!showMenu);
	const toggleShowSearch = (event) => {
		/* cache the event target */
		const currentTarget = event.currentTarget;

		/*
		 * If search is called when the menu overlay is open, the menu
		 * needs to be closed. Otherwise the 'dangerouslySetInnerHTML'
		 * style tag injection in MainMenu.jsx will lock scrolling on search results.
		 * initQuery now accepts cb as its optional third param
		 */
		const cb = () => updateShowMenu(false);

		import(/* webpackChunkName: "queryly" */ '@/utils/queryly')
			.then(({ default: initQueryly }) => {
				initQueryly(currentTarget, edition, cb);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const collapsedClass = isCollapsed ? 'navigation--collapsed' : '';
	// listen for scrolling events to toggle collapsed state when appropriate
	useEffect(() => {
		window.addEventListener(
			'scroll',
			throttle(function () {
				if (
					(!isCollapsedRef.current && window.pageYOffset > 100) ||
					(isCollapsedRef.current && window.pageYOffset <= 0)
				) {
					toggleCollapsed();
				}
			}, 100),
			{ passive: true }
		);
	}, []);

	const nav = data?.nav || {};
	const context = {
		nav,
		renderedMenu,
		seeEditions,
		setSeeEditions,
		showMenu,
		toggleSeeEditions,
		toggleShowMenu,
		toggleShowSearch,
		updateRenderedMenu
	};
	return (
		<NavContext.Provider value={context}>
			<header className={`navigation ${collapsedClass}`} data-hook="navigation">
				{renderedMenu && <MainMenu />}
				<NotificationBar />
				<NavBar />
				{enableMobileSubnav && <MobileSubnav />}
			</header>
		</NavContext.Provider>
	);
};

Navigation.displayName = 'Navigation';

export default Navigation;
