import React, { useState, useEffect, Fragment } from 'react';
import { object, string } from 'prop-types';
import socialLinks from './socialLinks';
import infoLinks from './infoLinks';
import getLegalLinks from './legalLinks';
import SvgImage from '@/components/icons/SvgImage';
import ELogoSvg from '@/images/elogo/dark.svg';
import privacyoptions from '@/images/privacyoptions.svg';
import createAnalyticsString from '@/utils/createAnalyticsString';
import { getCookie } from '@/utils/cookies';
import Translate from '@/components/i18n/Translate';
import { usePageContext } from '@/components/layout/page/PageContext';
import './GlobalFooter.scss';

/**
 * @function applyTarget
 * @param {String} link
 * @param {String} attribute
 * @returns {String|Undefined}
 */
const applyTarget = (link, attribute) => {
	const isInternal =
		!link ||
		link.charAt(0) === '/' ||
		link.includes('eonline.com') ||
		link.includes('mailto:') ||
		link === '#';

	if (attribute === 'target') {
		return isInternal ? undefined : '_blank';
	} else {
		return isInternal ? undefined : 'noopener noreferrer';
	}
};

/**
 * @function Button
 * @description returns a <button> or <a> with props and translated text
 * @param {Object} props
 * @param {Object} props.children - text being wrapped by button or anchor tag
 * @param {String} [props.href] - if passed in, Button will return <a>
 * @returns {React.ReactElement}
 */
const Button = ({ children, href, ...props }) => {
	return href ? (
		<a href={href} {...props}>
			{children}
		</a>
	) : (
		<button {...props}>{children}</button>
	);
};

Button.displayName = 'Button';

Button.propTypes = {
	children: object,
	href: string
};

/**
 * @function GlobalFooter
 * @returns {React.ReactElement}
 */
const GlobalFooter = () => {
	const [showIcon, setShowIcon] = useState(null);
	const { edition, intlLinks } = usePageContext();
	const newsTipLink = 'mailto:enewstipline@nbcuni.com?subject=News Tip';
	const advertiseLink =
		'https://together.nbcuni.com/advertise/?utm_source=e_online&utm_medium=referral&utm_campaign=property_ad_pages&utm_content=footer';
	const licensingLink = 'https://info.wrightsmedia.com/e-online-licensing';
	const homeLink = edition === 'us' ? '/' : `/${edition}`;
	const today = new Date();
	const thisYear = today.getFullYear();
	const legalLinks = getLegalLinks();

	useEffect(() => {
		setShowIcon(getCookie('geoEdition') === 'us');
	}, []);

	return intlLinks ? (
		<div className="global-footer container" data-hook="global-footer">
			<div className="global-footer__line global-footer__line--first" />

			<div className="global-footer__social" data-hook="global-footer-social">
				{socialLinks.map(({ image, link, name, targetEditions }, index) => {
					/* "showSocialLink" determines if "targetEditions"
						is absent or if there's a matching target edition */
					const showSocialLink = !targetEditions || targetEditions[edition];

					return showSocialLink ? (
						<a
							className="global-footer__social-link"
							data-analytics-linklocation="menu-overlay-social-share"
							data-analytics-linkname={name}
							href={intlLinks[link] || link}
							key={`sociallink-${index}`}
							id={`${name}-icon`}
							rel="noopener noreferrer"
							target="_blank"
						>
							<SvgImage className="global-footer__social-link-img" image={image} />
						</a>
					) : null;
				})}
			</div>
			<div
				className={`global-footer__info-links-container global-footer__info-links-container--${edition}-edition`}
			>
				<div
					className="global-footer__info-links columns is-mobile is-multiline is-centered"
					data-hook="global-footer-info"
				>
					{infoLinks.map(({ link, text, targetEditions, className }, index) => {
						/* "showInfoLink" determines if "targetEditions"
									is absent or if there's a matching target edition */
						const showInfoLink = !targetEditions || targetEditions[edition];

						return showInfoLink ? (
							<a
								key={`infolink-${index}`}
								className={`global-footer__info-link column is-narrow ${
									className || ''
								}`}
								data-analytics-linklocation="menu-overlay-footer"
								data-analytics-linkname={createAnalyticsString(text)}
								href={intlLinks[link] || link}
								target={applyTarget(intlLinks[link] || link, 'target')}
								rel={applyTarget(intlLinks[link] || link, 'rel')}
							>
								<Translate word={text} />
							</a>
						) : null;
					})}
				</div>
			</div>
			{edition === 'us' && (
				<div className="global-footer__buttons">
					<button
						className="button is-primary is-outlined footer-buttons__button"
						onClick={() => window.open(advertiseLink, '_self')}
					>
						Advertise With Us
					</button>
					<button
						className="button is-primary is-outlined footer-buttons__button"
						onClick={() => window.open(licensingLink, '_self')}
					>
						Licensing & Reuse of Content
					</button>
					<button
						className="button is-primary is-outlined footer-buttons__button"
						onClick={() => window.open(newsTipLink, '_self')}
					>
						submit a news tip
					</button>
				</div>
			)}
			<div className="global-footer__line" />
			<a
				className="global-footer__e-logo"
				href={homeLink}
				data-hook="global-footer-logo-link"
			>
				<SvgImage image={ELogoSvg} className="global-footer__e-logo-svg" />
			</a>
			<p className="global-footer__tagline">
				<Translate word="FOOTER_TAGLINE" />
			</p>

			<div className="global-footer__legal" data-hook="global-footer-legal">
				{legalLinks.map(
					({ className, link, text, targetEditions, hasPrivacyIcon }, index) => {
						/* "showLegalLink" determines if "targetEditions" 
						is absent or if there's a matching target edition */
						const showLegalLink = !targetEditions || targetEditions[edition];

						const legalLinkProps = {
							'data-analytics-linklocation': 'menu-overlay-footer',
							'data-analytics-linkname': createAnalyticsString(text),
							className: `global-footer__legal-link ${className || ''}`,
							href: link ? intlLinks[link] || link : undefined,
							key: `legallink-${index}`,
							rel: applyTarget(intlLinks[link] || link, 'rel'),
							target: applyTarget(intlLinks[link] || link, 'target')
						};

						return showLegalLink ? (
							<Fragment key={`global-footer-legal-${index}`}>
								{hasPrivacyIcon && showIcon && (
									<SvgImage
										image={privacyoptions}
										className="privacyoptions-icon"
									/>
								)}
								<Button {...legalLinkProps}>
									<Translate word={text} />
								</Button>
							</Fragment>
						) : null;
					}
				)}
			</div>
			<p className="global-footer__copyright">
				© {thisYear} E! Entertainment Television, LLC A Division of NBCUniversal. All rights
				reserved.
			</p>
		</div>
	) : null;
};

GlobalFooter.displayName = 'GlobalFooter';

export default GlobalFooter;
