import React, { useEffect } from 'react';
import { useParamToggleContext } from '@/components/param-toggle/ParamToggleContext';

import { usePageContext, useFeatureToggleContext } from '@/components/layout/page/PageContext';
import { IDENTITY_SDK, APP_ENV } from '@/config/config';
import { withErrorBoundary } from '@/components/error/ErrorBoundary';
import { func } from 'prop-types';

function loadScript(url, callback) {
	const script = document.createElement('script');
	script.type = 'text/javascript';

	if (script.readyState) {
		// IE
		script.onreadystatechange = function () {
			if (script.readyState === 'loaded' || script.readyState === 'complete') {
				script.onreadystatechange = null;
				callback();
			}
		};
	} else {
		// Others
		script.onload = function () {
			callback();
		};
	}

	script.src = url;
	document.getElementsByTagName('head')[0].appendChild(script);
}

/**
 * @function Identity
 * @description Loads in NBCUniversal Identity SDK
 * https://github.com/nbcdotcom/identity-sdk-kmp/blob/main/sdk-ui-web-library/README.md
 * @param {Function} setAuthentication updates user authentication state `identityAuthentication` in page context
 * @return {React.ReactElement}
 */

const Identity = ({ setAuthentication }) => {
	const { edition } = usePageContext();
	const { IDENTITY_ENABLED } = useFeatureToggleContext();
	const { IDENTITY_ENABLED_PARAM, THIRD_PARTY_ENABLED } = useParamToggleContext();

	useEffect(() => {
		if (
			edition === 'us' &&
			IDENTITY_SDK &&
			(IDENTITY_ENABLED || IDENTITY_ENABLED_PARAM) &&
			THIRD_PARTY_ENABLED
		) {
			loadScript(IDENTITY_SDK, () => {
				// Initializes Identity and loads in the config file (stage or prod)
				// https://github.com/nbcdotcom/identity-sdk-eonline-assets-config/tree/stage/web-sdk/config
				// See #edo_alerts-chatops-sdk-eonline Slack channel for instructions
				// to update the config file
				window.nbc.identity.initialize('eolweb', {
					debug: APP_ENV !== 'production',
					version: '2',
					env: APP_ENV !== 'production' ? 'stage' : 'production',
					useBaseSdkLocation: true
				});

				// update page context with current authentication state
				window.nbc.identity.on('authenticationState', (status) => {
					setAuthentication(status);
				});

				window.nbc.identity.on('windowState', (status) => {
					if (
						status === 'closed' &&
						window.nbc.identity.registrationResult === 'registered'
					) {
						window.location.href =
							'https://www.eonline.com/news/e_insider?source=nbcuidentity&medium=einsidermodal';
					}
				});
			});
		}
	}, [IDENTITY_ENABLED, IDENTITY_ENABLED_PARAM, THIRD_PARTY_ENABLED, edition, setAuthentication]);

	return IDENTITY_SDK && (IDENTITY_ENABLED || IDENTITY_ENABLED_PARAM) ? (
		<div className="identity-login"></div>
	) : null;
};

Identity.propTypes = {
	setAuthentication: func.isRequired
};

Identity.displayName = 'Identity';

export default withErrorBoundary(Identity);
