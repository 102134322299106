import React, { useState, useEffect } from 'react';
import { usePageContext } from '@/components/layout/page/PageContext';
import MpsAd from '@/components/mps/MpsAd';
import './TopBannerAd.scss';

/**
 * @const editionsWithMobileAd
 * @description case insensitive regex targeting edition codes where mobile ad should be included
 */
const editionsWithMobileAd = /^(AR|BR|CO|MX|VE|DE|US)$/i;

/**
 * @function TopBannerAd
 * @description wraps MpsAd with rules for a Top Banner ad
 * @returns {React.ReactElement}
 */
const TopBannerAd = () => {
	const { edition } = usePageContext();
	const isEditionWithoutMobileAd = !editionsWithMobileAd.test(edition);
	const [sticky, setSticky] = useState(true);

	useEffect(() => {
		const backupTimeout = setTimeout(() => {
			setSticky(false);
		}, 7000);
		window.addEventListener('mpsadviewed', (e) => {
			if (e?.detail?.slot?.includes('topbanner')) {
				clearTimeout(backupTimeout);
				setTimeout(() => {
					setSticky(false);
				}, 3000);
			}
		});
	}, []);

	return (
		<MpsAd
			id="topbanner"
			slot="topbanner"
			className={`adlayout mps-top-banner ${
				sticky ? 'mps-top-banner-sticky' : 'top-banner-no-sticky'
			}`}
			centered
			isHiddenMobile={isEditionWithoutMobileAd}
			isHiddenOnError={true}
		/>
	);
};

TopBannerAd.displayName = 'TopBannerAd';

export default TopBannerAd;
