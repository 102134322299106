import React from 'react';
import { editionList } from '@/components/i18n/editionList';
import { usePageContext } from '@/components/layout/page/PageContext';
import Translate from '@/components/i18n/Translate';
import intlUrl from '@/components/i18n/intlUrl';

/**
 * @function Editions
 * @description Provides a list of editions
 * @returns {React.ReactElement}
 */
const Editions = () => {
	const { edition } = usePageContext();
	const editions = editionList;

	return (
		<div className="main-menu__section">
			<span className="main-menu__section-title">
				<Translate word="EDITIONS" />
			</span>
			<div className='main-menu__section-columns'>
				{editions.map((ed) =>
					ed.edition !== edition ? (
						<a
							className="main-menu__section-item"
							href={intlUrl(ed.edition, '/')}
							key={`edition-${ed.edition}`}
						>
							{ed.name}
						</a>
					) : null
				)}
			</div>
		</div>
	);
};

Editions.displayName = 'Editions';

export default Editions;
