export default [
	{
		link: 'ABOUT_US',
		text: 'ABOUT_US',
		targetEditions: {
			ap: true,
			au: true,
			ca: true,
			de: true,
			fr: true,
			uk: true,
			us: true
		}
	},
	{
		link: 'FOLLOW_US',
		text: 'FOLLOW_US',
		targetEditions: {
			us: true
		}
	},
	{
		link: 'GET_E',
		text: 'Get E!',
		targetEditions: {
			au: true,
			uk: true
		}
	},
	{
		link: 'CONTACT_US',
		text: 'CONTACT_US',
		targetEditions: {
			ar: true,
			au: true,
			br: true,
			ca: true,
			co: true,
			de: true,
			fr: true,
			mx: true,
			uk: true,
			us: true,
			ve: true
		}
	},
	{
		link: 'FAQ',
		text: 'FAQ',
		targetEditions: {
			ap: true,
			us: true
		}
	},
	{
		link: 'CAREERS',
		text: 'CAREERS',
		targetEditions: {
			us: true
		}
	},
	{
		link: 'CLOSED_CAPTIONING',
		text: 'CLOSED_CAPTIONING',
		targetEditions: {
			us: true
		}
	},
	{
		link: 'WATCH_FULL_EPISODES',
		text: 'WATCH_FULL_EPISODES',
		targetEditions: {
			us: true
		}
	},
	{
		link: 'SITE_MAP',
		text: 'SITE_MAP',
		targetEditions: {
			au: true,
			ca: true,
			de: true,
			fr: true,
			uk: true,
			us: true
		}
	},
	{
		link: '/info/intl_tv_channels',
		text: 'E! International TV Channels',
		targetEditions: {
			us: true
		}
	},
	{
		link: 'CUSTOMER_SUPPORT',
		text: 'Customer Support',
		targetEditions: {
			ap: true,
			au: true,
			de: true,
			ca: true,
			uk: true,
			us: true
		}
	},
	{
		link: 'APPS',
		text: 'APPS',
		targetEditions: {
			au: true,
			fr: true
		}
	},
	{
		link: 'PEOPLES_CHOICE',
		text: "People's Choice",
		targetEditions: {
			ap: true,
			au: true,
			ca: true,
			de: true,
			fr: true,
			uk: true,
			us: true
		}
	},
	{
		link: '/peoples_choice_awards/faq',
		text: "People's Choice FAQ"
	},
	{
		link: '/fr/cgv',
		text: 'CGV',
		targetEditions: {
			fr: true
		}
	},
	{
		link: 'AFFILIATES',
		text: 'AFFILIATES',
		targetEditions: {
			de: true,
			fr: true
		}
	},
	{
		link: 'RSS_FEEDS',
		text: 'RSS_FEEDS',
		targetEditions: {
			au: true,
			ca: true,
			de: true,
			fr: true,
			uk: true
		}
	},
	{
		link: '/de/impressum',
		text: 'Impressum',
		targetEditions: {
			de: true
		}
	},
	{
		link: 'REMOVAL_POLICY',
		text: 'Política De Remoción',
		targetEditions: {
			ar: true,
			co: true,
			mx: true,
			ve: true
		}
	},
	{
		link: 'http://nbc.researchresults.com/?s=639',
		text: 'Earn Gift Cards For Feedback',
		targetEditions: {
			us: true
		},
		className: 'global-footer__link--full-line'
	}
];
