import React from 'react';
import MainMenuHeader from './header/MainMenuHeader';
import MainMenuCategories from './categories/MainMenuCategories';
import ShowsButtons from './shows/ShowsButtons';
import ShowPackages from './shows/ShowPackages';
import MainMenuHotTopics from './hot-topics/MainMenuHotTopics';
import Editions from './editions/Editions';
import Featured from './featured/Featured';
import Follow from './follow/Follow';
import MoreE from './more-e/MoreE';
import { useNavContext } from '@/components/layout/navigation/NavContext';
import './MainMenu.scss';

/**
 * @function MainMenu
 * @description MainMenu is the Main Menu Overlay that comprises the site's comprehensive navigation tool
 * @returns {React.ReactComponentElement}
 */
const MainMenu = () => {
	const { showMenu } = useNavContext();

	const hideMenuClass = showMenu ? '' : 'main-menu--hide';

	return (
		<nav className={`main-menu ${hideMenuClass}`} data-hook="main-menu" data-testid="main-menu">
			{/* Adds a style elements to hide the main scrollbar when rendering MainMenu
				using html: { overflow-y: hidden }. Is necessary to hide the scrollbar on Firefox */}
			{showMenu && (
				<style
					dangerouslySetInnerHTML={{
						__html: `body::-webkit-scrollbar { width: 0; }
								html { overflow-y: hidden; }`
					}}
				/>
			)}
			<MainMenuHeader />

			<div className="main-menu__all-content" data-hook="main-menu-all-content">
				<div className="columns is-mobile is-tablet is-desktop container is-align-items-baseline">
					{/* Show Packages */}
					<div className="column is-12-mobile is-half-tablet is-8-desktop">
						<div className="main-menu-shows__container" data-hook="main-menu-shows">
							<div className="is-hidden-desktop is-hidden-tablet">
								<MainMenuHotTopics />
							</div>
							<ShowPackages />
							<ShowsButtons />
						</div>
						<div className="is-hidden-mobile">
							<MoreE />
						</div>
					</div>
					{/* Categories */}
					<div className="column is-4-desktop is-half-tablet is-12-mobile">
						<div className="is-hidden-mobile">
							<MainMenuHotTopics />
						</div>
						<MainMenuCategories />
						<Featured />
						<Editions />
						<Follow />
						<div className="is-hidden-desktop is-hidden-tablet">
							<MoreE />
						</div>
					</div>
				</div>
			</div>
		</nav>
	);
};

MainMenu.displayName = 'MainMenu';

export default MainMenu;
