import React, { useState, forwardRef, Fragment, createContext, useContext } from 'react';
import { any, array, bool, element, func, number, object, oneOfType, string } from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { PageContext } from '@/components/layout/page/PageContext';
import striptags from 'striptags';
import generateResizeLink from '@/utils/generateResizeLink';
import GatedLink from '@/components/identity/GatedLink';
import './Thumb.scss';

const ThumbContext = createContext({
	type: '2x1',
	thumbnail: {}
});

const useThumbContext = () => useContext(ThumbContext);
/**
 * @const thumbTypes
 * @description provides className strings keyed to supported aspect ratios
 */
const thumbTypes = {
	'1x1': 'thumb thumb--1x1',
	'1x2': 'thumb thumb--1x2',
	'2x1': 'thumb thumb--2x1',
	'2x3': 'thumb thumb--2x3',
	'3x4': 'thumb thumb--3x4',
	'4x3': 'thumb thumb--4x3',
	'16x9': 'thumb thumb--16x9'
};

/**
 * @function Span
 * @param {Object} props
 * @param {String} href
 * @return {React.ReactElement}
 */
const Span = forwardRef(({ href, ...props }, ref) => <span ref={ref} {...props} />);

Span.propTypes = {
	href: string
};

Span.displayName = 'Span';

/**
 * @function generateLink
 * @description generate an image link
 * @param {String} props.uri
 * @param {Number} [props.thumbWidth] original image width
 * @param {Number} [props.thumbHeight] original image height
 * @param {String} [props.width] The desired width
 * @param {String} [props.width2x] The maximum 2x width
 * @param {String} [props.height] The desired height
 * @returns {Object}
 */
const generateLink = ({ uri, thumbWidth, thumbHeight, width, width2x, height, type }) => {
	// grab custom width or else default to original image width;
	const w = parseInt(width) || thumbWidth;
	// get double sized image
	const doubleWidth = parseInt(width2x) || w * 2;
	// cap double width to be no larger than the original image width
	const w2x = thumbWidth && doubleWidth > thumbWidth ? thumbWidth : doubleWidth;

	const h =
		type === '2x1'
			? w / 2
			: type === '1x1'
			? w * 1
			: type === '1x2'
			? w * 2
			: type === '16x9'
			? Math.ceil(w * 0.5625)
			: type === '3x4'
			? Math.ceil(w * 1.33)
			: type === '4x3'
			? Math.ceil(w * 0.75)
			: isNaN(parseInt(height)) && thumbHeight && thumbWidth
			? Math.ceil((thumbHeight / thumbWidth) * w) // calculate the height proportionate to the width if no type or height was specified
			: height;
	const crop = true; // Always crop to size

	return uri ? generateResizeLink(uri, w, h, crop, w2x) : {};
};

/**
 * @function generateStyle
 * @description creates style object for wrapper div to overried css
 * @param {String} type - passed into Thumb ("2x1" or "1x1")
 * @param {Number} width thumbnail width
 * @param {Number} height thumbnail height
 * @returns {Object}
 */
const generateStyle = (type, width, height) => {
	// leaving this open for adding more styles other than padding-top
	const style = {};
	// override padding-top when type(ratio) is not passed in
	// this creates custom padding relative to the ratio of the image
	if (!type && height && width) style.paddingTop = `${Math.floor((height / width) * 100)}%`;
	return { style };
};

/**
 * @function Thumb
 * @description Creates a 1x1, 2x1, 1x2, 3x4, 4x3, or 16x9 thumbnail
 * @param {Object} props
 * @param {String} [props.type] "2x1" or "1x1" or "16x9"
 * @param {Object} props.thumbnail
 * @param {String} [props.width] The desired width
 * @param {String} [props.width2x] The maximum 2x width if different than 2 * width
 * @param {String} [props.height] The desired height
 * @param {String} [props.href] if onClick is set, href will be ignored
 * @param {String} [props.srcSet] Override srcset string from generateResizeLink
 * @param {Function} [props.onClick]
 * @param {String} [props.className]
 * @param {Object} [props.analyticsData] click tracking data attributes and values
 * @param {React.ReactElement} [props.children]
 * @param {String} [props.rel] - rel prop for <a> tag
 * @param {String} [props.title]
 * @param {Boolean} [props.withLink] Whether to use a link as its parent
 * @param {String} [props.target] The target attribute specifies where to open the linked document
 * @param {Boolean} [props.lazyLoad] whether to lazyLoad the img in
 * @param {Boolean} [props.isPicture] whether to use a <picture> element
 * @return {React.ReactElement}
 */
const Thumb = ({
	type,
	thumbnail,
	width,
	width2x,
	height,
	href,
	onClick,
	analyticsData,
	className,
	rel,
	title,
	srcSet,
	children,
	withLink,
	target,
	isPicture,
	lazyLoad
}) => {
	const { useContext } = React;
	const { isAmp } = useContext(PageContext);

	const thumbWidth = parseInt(thumbnail?.width) || parseInt(thumbnail?.sourceWidth) || null;
	const thumbHeight = parseInt(thumbnail?.height) || parseInt(thumbnail?.sourceHeight) || null;

	const styleWidth = (type && (!width || !height)) || !width || !height ? thumbWidth : width;
	const styleHeight = (type && (!width || !height)) || !width || !height ? thumbHeight : height;

	const { src, srcset } = generateLink({
		uri: thumbnail?.uri,
		thumbWidth,
		thumbHeight,
		width,
		width2x,
		height,
		type
	});

	const thumbType = thumbTypes[type] || 'thumb';

	const [isLoaded, setIsLoaded] = useState(!lazyLoad);
	const Wrap = withLink ? GatedLink : Span;

	const [ref, inView] = useInView({
		triggerOnce: true,
		threshold: 0,
		rootMargin: '150%'
	});

	// invalid images return -1 from the BE
	const ampWidth = (thumbWidth >= 0 && thumbWidth) || width;
	const ampHeight = (thumbHeight >= 0 && thumbHeight) || height;
	if (!thumbnail || !src || (isAmp && (!ampWidth || !ampHeight))) return null;

	const cleanTitle =
		(title && striptags(title).replace('"', '')) ||
		(thumbnail?.title && striptags(thumbnail.title).replace('"', ''));

	return (
		<Wrap
			ref={ref}
			{...(withLink && { rel })}
			{...generateStyle(type, styleWidth, styleHeight)}
			className={`${thumbType} ${className || ''} ${
				isLoaded || isAmp ? 'thumb--inview' : ''
			}`}
			data-hook="thumb"
			onClick={onClick}
			{...(withLink && analyticsData)}
			href={href}
			{...(withLink && { target: target })}
		>
			{isAmp ? (
				<amp-img
					width={ampWidth}
					height={ampHeight}
					layout="responsive"
					srcSet={srcSet || srcset}
					src={src}
					alt={cleanTitle}
				/>
			) : !lazyLoad ? (
				<img srcSet={srcSet || srcset} src={src} alt={cleanTitle} />
			) : inView && isPicture ? (
				<ThumbContext.Provider
					value={{
						type,
						thumbnail,
						thumbWidth,
						thumbHeight,
						title: cleanTitle
					}}
				>
					<picture
						onLoad={() => {
							// Set loaded on the next tick
							setTimeout(() => {
								setIsLoaded(true);
							}, 0);
						}}
					>
						{children}
						<img srcSet={srcSet || srcset} src={src} alt={cleanTitle} />
					</picture>
				</ThumbContext.Provider>
			) : (
				inView && (
					<Fragment>
						<img
							srcSet={srcSet || srcset}
							src={src}
							alt={cleanTitle}
							onLoad={() => {
								// Set loaded on the next tick
								setTimeout(() => {
									setIsLoaded(true);
								}, 0);
							}}
						/>
						{children}
					</Fragment>
				)
			)}
		</Wrap>
	);
};

Thumb.defaultProps = {
	withLink: true,
	target: '_self',
	lazyLoad: true
};

Thumb.propTypes = {
	type: string,
	thumbnail: object.isRequired,
	width: string,
	width2x: string,
	height: string,
	href: string,
	srcSet: string,
	onClick: func,
	analyticsData: object,
	className: string,
	rel: string,
	title: string,
	children: oneOfType([array, element]),
	withLink: bool,
	target: string,
	lazyLoad: bool,
	isPicture: bool
};

Thumb.displayName = 'Thumb';

/**
 * @function Thumb.Source
 * @description source for <picture>
 * @param {Object} props
 * @param {String} props.width
 * @param {String} [props.width2x]
 * @param {String} props.media
 * @return {React.ReactElement}
 */
Thumb.Source = ({ width, width2x, media }) => {
	const { thumbnail, thumbWidth, thumbHeight, type } = useThumbContext();
	const { src, srcset } = generateLink({
		uri: thumbnail?.uri,
		thumbWidth,
		thumbHeight,
		width,
		width2x,
		type
	});
	return <source srcSet={`${src}, ${srcset}`} media={media} />;
};

Thumb.Source.displayName = 'Thumb.Source';

Thumb.Source.propTypes = {
	width: oneOfType([number, string]).isRequired,
	width2x: string,
	media: string.isRequired
};

export default Thumb;
