/**
 * @function intlUrl
 * @param {String} edition - 2 letter code in lowercase
 * @param {String} uri - begins with /
 * @returns {String}
 */
export default (edition, uri) => {
	// return without processing if uri is empty or an external link
	if (uri == null || /^https?:\/\//.test(uri)) return uri;

	const isUS = /^us$/i.test(edition);
	const path = isUS ? uri : uri.replace(/\/$/, ''); // remove trailing slash, if any
	const hasEditionInUrl = /^\/?[a-zA-Z]{2}\//.test(path);

	if (hasEditionInUrl) return path;
	return (isUS ? path : `/${edition}${path}`).toLowerCase();
};
