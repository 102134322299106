import React, { createContext, useContext } from 'react';
import useThrottledResizeObserver from '@/utils/useThrottledResizeObserver';
import { array, element, oneOfType } from 'prop-types';

const ResizePageContext = createContext({
	width: 0,
	height: 0
});

export const useResizePageObserver = () => useContext(ResizePageContext);

/**
 * @function ResizePageObserver
 * @param {Object} props
 * @param {React.ReactElement} props.children
 * @return {React.ReactElement}
 */
export const ResizePageObserver = ({ children }) => {
	const observer = useThrottledResizeObserver(300);
	const { ref } = observer;
	return (
		<ResizePageContext.Provider value={observer}>
			<div id="page" ref={ref}>
				{children}
			</div>
		</ResizePageContext.Provider>
	);
};

ResizePageObserver.propTypes = {
	children: oneOfType([element, array]).isRequired
};

ResizePageObserver.displayName = 'ResizePageObserver';
