/**
 * @const editionList
 * @description provides edition (country code string) and name of each national edition
 * @returns {Array}
 */
export const editionList = [
	{
		edition: 'us',
		name: 'United States'
	},
	{
		edition: 'ca',
		name: 'Canada'
	}
];
