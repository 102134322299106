// @ts-nocheck
import React, { useState, useEffect, createContext, useContext } from 'react';
import { any } from 'prop-types';
import { APP_ENV, IS_PREVIEW_MODE } from '@/config/config';
import { useParamToggleContext } from '@/components/param-toggle/ParamToggleContext';
import { useFeatureToggleContext } from '@/components/layout/page/PageContext';
import loadScript from '@/utils/loadScript';

const OneTrustContext = createContext({
	gdprApplies: 0,
	gdprTcString: null
});

/**
 * @function useOneTrustContext
 * @description The oneTrust GDPR values
 * @return {Object}
 */
export const useOneTrustContext = () => useContext(OneTrustContext);

/**
 * @function getGDPRApplies
 * @description exyracts the consent value from the one trust banner. ('0' if user consents, '1' if user doesn't)
 * @param {Object|Null} gdprData
 * @return {Number}
 */
const getGDPRApplies = (gdprData) => {
	return gdprData?.gdprApplies ? 1 : 0;
};

const getGDPRContentData = (gdprData) => {
	return gdprData?.tcString;
};

/**
 * @function OneTrustProviderWrap
 * @description GDPR "consent string" banner for cookies
 * @return {React.ReactHTML}
 */
export const OneTrustProviderWrap = ({ children }) => {
	const [gdprData, setGDPRData] = useState(null);
	const gdprApplies = getGDPRApplies(gdprData);
	const gdprTcString = getGDPRContentData(gdprData);
	const isProd = APP_ENV === 'production';
	const domainScript = isProd
		? 'f7922f3b-1487-4360-9a55-04a5ff49b019'
		: 'f7922f3b-1487-4360-9a55-04a5ff49b019-test';
	const integritySha = 'sha384-TKdmlzVmoD70HzftTw4WtOzIBL5mNx8mXSRzEvwrWjpIJ7FZ/EuX758yMDWXtRUN';
	const { THIRD_PARTY_ENABLED, TCF_STUB, NEW_INTEGRITY_PARAM } = useParamToggleContext();
	const {
		ONE_TRUST_INTEGRITY,
		HAS_ONETRUST_TCF_STUB,
		NEW_INTEGRITY_FEATURE
	} = useFeatureToggleContext();

	const hasTcfStub = (TCF_STUB || HAS_ONETRUST_TCF_STUB) && TCF_STUB !== false;

	useEffect(() => {
		const callback = (data, success) => {
			if (success) setGDPRData(data);
		};

		loadScript({
			src: `https://cdn.cookielaw.org/consent/${domainScript}/otSDKStub.js`,
			defer: true,
			dataset: {
				'domain-script': domainScript
			},
			crossOrigin: 'anonymous',
			integrity:
				NEW_INTEGRITY_PARAM || NEW_INTEGRITY_FEATURE ? integritySha : ONE_TRUST_INTEGRITY,
			onLoad: () => {
				__tcfapi('addEventListener', 2, callback);
			}
		});
		// Unmount
		return () => {
			__tcfapi && __tcfapi('removeEventListener', 2, callback);
		};
	}, [domainScript, ONE_TRUST_INTEGRITY, NEW_INTEGRITY_PARAM, NEW_INTEGRITY_FEATURE]);

	return !hasTcfStub || IS_PREVIEW_MODE || !THIRD_PARTY_ENABLED ? (
		<OneTrustContext.Provider
			value={{
				gdprApplies: gdprApplies || 0,
				gdprTcString
			}}
		>
			{children}
		</OneTrustContext.Provider>
	) : (
		<OneTrustContext.Provider
			value={{
				gdprApplies,
				gdprTcString
			}}
		>
			{children}
			<script
				src="https://cdn.cookielaw.org/consent/tcf.stub.js"
				type="text/javascript"
				charSet="UTF-8"
				defer
			></script>
			<script
				type="text/javascript"
				dangerouslySetInnerHTML={{
					__html: `function OptanonWrapper() {}`
				}}
			></script>
		</OneTrustContext.Provider>
	);
};

OneTrustProviderWrap.propTypes = {
	children: any
};

OneTrustProviderWrap.displayName = 'OneTrustProviderWrap';
