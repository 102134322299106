import React from 'react';
import striptags from 'striptags';
import createAnalyticsString from '@/utils/createAnalyticsString';
import SvgImage from '@/components/icons/SvgImage';
import HotTopicsIcon from '@/images/hot-topics-flame.svg';
import { useNavContext } from '@/components/layout/navigation/NavContext';
import Translate from '@/components/i18n/Translate';
import './MainMenuHotTopics.scss';

/**
 * @function MainMenuHotTopics
 * @description Provides a Hot Topics list component for the Main Menu overlay
 * @returns {React.ReactElement}
 */

const MainMenuHotTopics = () => {
	const { nav } = useNavContext();
	const links = nav?.hotTopics?.links.slice(3) || [];

	return links.length > 0 ? (
		<div className="main-menu__section">
			<span className="main-menu__section-title">
				<SvgImage className="main-menu__hottopics-icon-svg" image={HotTopicsIcon} />
				<Translate word="HOT_TOPICS" />
			</span>
			<div className="main-menu__section-columns">
				{links.map(({ reference, displayTitle }, index) => {
					const title = striptags(displayTitle);
					const uri = reference?.uri;

					return uri && title ? (
						<a
							key={`main-menu-topic-${index}`}
							className='main-menu__section-item'
							data-analytics-linkname={createAnalyticsString(title)}
							data-analytics-linklocation="menu-overlay-hot-topics"
							data-hook="main-menu-hot-topic"
							href={uri}
						>
							{title}
						</a>
					) : null;
				})}
			</div>
		</div>
	) : null;
};

MainMenuHotTopics.displayName = 'MainMenuHotTopics';

export default MainMenuHotTopics;
