/**
 * @function getCookie
 * @description retrieves a cookie by name
 * @param {String} name the cookie name
 * @returns {String}
 */
export const getCookie = (name) => {
	if (typeof document === 'undefined') return '';

	const cname = name + '=';
	const decodedCookie = decodeURIComponent(document.cookie);
	const ca = decodedCookie.split(';');

	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(cname) === 0) {
			return c.substring(cname.length, c.length);
		}
	}
	return '';
};

/**
 * @function setCookie
 * @description sets a cookie value
 * @param {String} name the cookie name
 * @param {String} value the cookie value
 * @param {String} [expiration] the expiration date, default value is 1 year
 */
export const setCookie = (name, value, expiration = 365) => {
	var d = new Date();
	d.setTime(d.getTime() + expiration * 24 * 60 * 60 * 1000);
	var expires = 'expires=' + d.toUTCString();
	document.cookie = name + '=' + value + ';' + expires + ';path=/;domain=.eonline.com';
};
