import React from 'react';
import { usePageContext } from '@/components/layout/page/PageContext';
import { useNavContext } from '@/components/layout/navigation/NavContext';
import parseHtml from '@/utils/parseHtml';
import SvgImage from '@/components/icons/SvgImage';
import ChevronSvg from '@/images/chevron.svg';
import { ampParams } from './notificationConstants';
import './NotificationBar.scss';

/**
 * @function NotificationBar
 * @description provides Notification Bar banner
 * @param {Object} [props.notificationBar]
 * @returns {React.ReactElement}
 */
const NotificationBar = () => {
	const { isAmp } = usePageContext();
	const { nav } = useNavContext();
	const notificationBar = nav?.notificationBar || {};
	const { enabled, links, snipeText } = notificationBar;
	const notification = links?.links?.[0] || {};
	const displayTitle = parseHtml(notification.displayTitle);
	const linkAddress = `${notification?.reference?.uri}${isAmp ? `?${ampParams}` : ''}`;

	return enabled && displayTitle && linkAddress ? (
		<a
			className="notification-bar"
			data-analytics-linklocation="notification-bar"
			data-analytics-linkname="article"
			data-hook="notification-bar"
			href={linkAddress}
			tabIndex="0"
		>
			<div className="notification-bar__container">
				<p className="notification-bar__snipetext">{snipeText}</p>
				<div className="notification-bar__icon">
					<SvgImage className="chevron" image={ChevronSvg} />
				</div>
				<div className="notification-bar__title" data-hook="notification-bar-title">
					{displayTitle}
				</div>
			</div>
		</a>
	) : null;
};

NotificationBar.displayName = 'NotificationBar';

export default NotificationBar;
