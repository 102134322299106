import React from 'react';
import { useParamToggleContext } from '@/components/param-toggle/ParamToggleContext';
import { withErrorBoundary } from '@/components/error/ErrorBoundary';
import { ADOBE_LAUNCH } from '@/config/config';

/**
 * @function AdobeLaunch
 * @return {React.ReactElement}
 */

const AdobeLaunch = () => {
	const { ADOBE_LAUNCH_ENABLED, THIRD_PARTY_ENABLED } = useParamToggleContext();
	return ADOBE_LAUNCH_ENABLED && THIRD_PARTY_ENABLED ? (
		<script src={`//assets.adobedtm.com/launch-${ADOBE_LAUNCH}.min.js`} async />
	) : null;
};

AdobeLaunch.displayName = 'AdobeLaunch';

export default withErrorBoundary(AdobeLaunch);
