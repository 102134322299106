import { createContext, useContext } from 'react';

/**
 * @constant MpsContext
 * @param {Boolean} isInWidget if Ad is in a widget. Targeting uses "wid"
 */
export const MpsContext = createContext({
	isInWidget: false
});

/**
 * @function usePageContext
 * @description provides PageContext data when instantiated
 * @returns {Object}
 */
export const useMpsContext = () => useContext(MpsContext);
