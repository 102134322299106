/**
 * @function loadScript
 * @description programmatically load a script
 * @param {Object} config
 * @param {Object} config.src
 * @param {Function} [config.onLoad] onLoad callback
 * @param {Boolean} [config.defer] Whether to defer the script
 * @param {String} [config.integrity] The integrity sha value
 * @param {String} [config.crossOrigin] The crossorgin value
 * @param {String} [config.type] - type attribute for script tag
 */
const loadScript = ({ src, onLoad, dataset, integrity, crossOrigin, defer, type }) => {
	const exists = document.querySelector(`script[src="${src}"]`);
	if (exists) return;

	const head = document.getElementsByTagName('head')[0];
	const script = document.createElement('script');
	script.type = type || 'text/javascript';
	defer ? (script.defer = true) : (script.async = true);

	if (dataset && Object.keys(dataset).length) {
		Object.entries(dataset).forEach(([key, val]) => {
			script.setAttribute(`data-${key}`, val);
		});
	}
	if (typeof integrity === 'string') script.integrity = integrity;
	if (typeof crossOrigin === 'string') {
		script.setAttribute('crossorigin', crossOrigin);
	}
	script.onload = onLoad;
	script.src = src;
	head.appendChild(script);
};

export default loadScript;
