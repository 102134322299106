import React from 'react';
import Thumb from '@/components/thumb/Thumb';
import createAnalyticsString from '@/utils/createAnalyticsString';
import { useNavContext } from '@/components/layout/navigation/NavContext';
import { useResizePageObserver } from '@/components/layout/page/ResizePageObserver';
import breakpointType from '@/utils/breakpointType';
import Translate from '@/components/i18n/Translate';
import './ShowPackages.scss';

/**
 * @function ShowPackages
 * @description Provides a Show Packages component
 * @requires NavContext
 * @returns {React.ReactElement}
 */
const ShowPackages = () => {
	const { nav } = useNavContext();
	const links = nav?.shows?.links || [];
	const showsLinks = links.slice(0, 8);
	const { width } = useResizePageObserver();
	const type = breakpointType(width);
	const thumbType = type === 'mobile' ? '1x1' : '2x3';

	return showsLinks.length ? (
		<div className="main-menu__section" data-hook="show-packages">

			<span className="main-menu__section-title">
				<Translate word="FEATURED_SHOWS" />
			</span>
			<div className="columns main-menu__show-packages is-mobile">
				{showsLinks.map(({ reference, displayTitle }, index) =>
					reference ? (
						<span
							className="column is-half-mobile is-half-tablet is-4-desktop is-3-widescreen"
							data-hook="show-thumb"
							key={`menu-show-${index}`}
						>
							<Thumb
								analyticsData={{
									'data-analytics-linklocation': 'menu-overlay-shows',
									'data-analytics-linkname': createAnalyticsString(displayTitle)
								}}
								href={reference.uri}
								thumbnail={reference.thumbnail}
								title={displayTitle}
								type={thumbType}
								width="339"
								withLink={true}
							/>
						</span>
					) : null
				)}
			</div>
		</div>
	) : null;
};

ShowPackages.displayName = 'ShowPackages';

export default ShowPackages;
