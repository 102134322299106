import React, { Component } from 'react';

export class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		console.error(error, errorInfo);
	}

	render() {
		if (this.state.hasError) return null;
		return this.props.children;
	}
}

ErrorBoundary.displayName = 'ErrorBoundary';

/**
 * @function withErrorBoundary
 * @description A higher order component that wraps a component in a ErrorBoundary
 * @param {Function} Component the component that should be wrapped
 * @return {React.ReactElement}
 */
export const withErrorBoundary = (Component) => (props) => {
	return (
		<ErrorBoundary>
			<Component {...props} />
		</ErrorBoundary>
	);
};
