import React from 'react';
import { object } from 'prop-types';

const baseData = { '@context': 'http://schema.org' };

/**
 * @function StructuredData
 * @description convertes structured data to the correct format
 * @param {Object} [props]
 * @param {Object} [props.data] - page structured data
 * @returns {React.ReactElement}
 */
const StructuredData = ({ data }) => {
	return (
		<script
			type="application/ld+json"
			dangerouslySetInnerHTML={{
				__html: JSON.stringify({ ...baseData, ...data }, null, 4)
			}}
		/>
	);
};

StructuredData.propTypes = {
	data: object
};

StructuredData.displayName = 'StructuredData';

export default StructuredData;
