import React from 'react';
import { useNavContext } from '@/components/layout/navigation/NavContext';
import SvgImage from '@/components/icons/SvgImage';
import searchIconSvg from '@/images/search-icon.svg';
import closeIconSvg from '@/images/close-icon.svg';
import './MainMenuHeaderIcons.scss';

/**
 * @function MainMenuHeaderIcons
 * @description provides icons for Main Menu Overlay
 * @returns {React.ReactElement}
 */
const MainMenuHeaderIcons = () => {
	const { toggleShowMenu, toggleShowSearch } = useNavContext();

	return (
		<div className="main-menu-header__icons">
			<label htmlFor="queryly_toggle" className="main-menu-header__queryly-toggle">
				<div
					className="main-menu__search main-menu-header__icon queryly_search_button"
					data-analytics-linklocation="menu-overlay-header"
					data-analytics-linkname="search"
					onClick={toggleShowSearch}
					onKeyPress={toggleShowSearch}
					role="button"
					tabIndex="0"
				>
					<SvgImage className="main-menu__search-image" image={searchIconSvg} />
				</div>
			</label>

			<div
				className="main-menu__close main-menu-header__icon"
				data-analytics-linklocation="menu-overlay-header"
				data-analytics-linkname="close-icon"
				data-hook="close-overlay-link"
				onClick={() => {
					toggleShowMenu();
				}}
				onKeyPress={() => {
					toggleShowMenu();
				}}
				role="button"
				tabIndex="0"
			>
				<SvgImage className="main-menu__close-image" image={closeIconSvg} />
			</div>
		</div>
	);
};

MainMenuHeaderIcons.displayName = 'MainMenuHeaderIcons';

export default MainMenuHeaderIcons;
