import { setSlotCallbacks, removeSlotCallbacks } from './mps';
import nanoid from 'nanoid';

/**
 * @function logAdInfo
 * @description Logs the ads info
 * @param {Object} ad - The ad details
 * @param {String} ad.slot - The mps ad slot
 * @param {String} ad.targeting - The ad targeting keyword
 */
const logAdInfo = ({ slot, targeting }) => {
	console.info(`MPS ${slot} - ${targeting ? 'TARGETING:' : 'NO TARGETING'} `, targeting || '');
};

/**
 * @function insertRepeatableAd
 * @description Repeatable ads of the same slot type
 * @param {Object} ad - The ad details
 * @param {String} ad.id - The div's id. ex. #topbanner
 * @param {String} ad.slot - The mps ad slot
 * @param {String} ad.targeting - The ad targeting keyword
 */
const insertRepeatable = ({ id, slot, targeting }) => {
	mps?.cloneAd(id, slot, targeting || '');
};

/**
 * @function insertDefaultAd
 * @description 90% of ads will use this. This is the generic insertAd function from mps
 * @param {Object} ad - The ad details
 * @param {HTMLElement} ad.el - A DOM el. In react this would be this.ref.current
 * @param {String} ad.slot - The mps ad slot
 * @param {String} ad.targeting - The ad targeting keyword
 */
const insertDefaultAd = ({ el, slot, targeting }) => {
	mps?.insertAd(el, slot, targeting || '');
};

/**
 * @class MpsAd
 * @description An Ad instance
 * @param {Object} settings - The ad details
 * @param {HTMLElement} settings.el - A DOM el. In react this would be this.ref.current
 * @param {String} [settings.id] - The dom id, ex. '#topbanner'. (Must include #)
 * @param {String} settings.slot - The mps ad slot
 * @param {Boolean} [settings.isInWidget] whether ad is in a widget (uses wid)
 * @param {String} [settings.targeting] - The ad targeting keyword
 * @param {Function} [settings.success] - A success CB
 * @param {Function} [settings.error] - An error CB
 * @param {Function} [settings.insertAd] - An insertAdOveride that MPS provides
 */
class MpsAd {
	constructor(settings) {
		this.settings = Object.assign({}, MpsAd.defaultSettings, settings);

		// Set if an ID wasn't provided
		this.setId();

		if (mps?._gptloaded) {
			this.renderAd();
		} else {
			mps._queue.gptloaded.push(() => {
				this.renderAd();
			});
		}
	}

	static defaultSettings = {
		id: null,
		el: null,
		slot: '',
		targeting: '',
		repeatable: false,
		success: null,
		error: null,
		insertAd: null,
		isInWidget: false
	};

	getInsertAdType() {
		const { insertAd, repeatable } = this.settings;
		if (repeatable) return insertRepeatable;
		if (typeof insertAd === 'function') return insertAd;
		return insertDefaultAd;
	}

	setId() {
		const { id, slot, repeatable } = this.settings;
		if (typeof id === 'string' || !repeatable) return;
		this.id = slot + '-' + nanoid();
		this.settings.id = '#' + this.id;
		this.settings.el.setAttribute('id', this.id);
	}

	destroy() {
		if (mps && typeof mps.destroyAds === 'function') mps.destroyAds(this.settings.slot);
		removeSlotCallbacks(this.settings.slot);
	}

	isCBForThisInstance(eo) {
		const parent = document.getElementById(eo._mps.adslot)?.parentNode;
		return parent === this.settings.el;
	}

	formatSettings() {
		const { isInWidget, targeting } = this.settings;
		if (!targeting) return this.settings;
		return Object.assign({}, this.settings, {
			targeting: isInWidget ? `wid=${targeting}` : `akw=${targeting}`
		});
	}

	renderAd() {
		const { success, error, slot, el } = this.settings;
		const insertAd = this.getInsertAdType();
		const settings = this.formatSettings();
		// Log debugging Ad info
		// logAdInfo(settings);

		// Insert Ad based on ad type function
		insertAd(settings);

		if (typeof success === 'function' || typeof error === 'function') {
			setSlotCallbacks(slot, {
				success: (eo) => {
					if (!success || !this.isCBForThisInstance(eo)) return;
					success({ eo, el });
				},
				error: (eo) => {
					if (!error || !this.isCBForThisInstance(eo)) return;
					error({ eo, el });
				}
			});
		}
	}
}

export default MpsAd;
