import { gql } from '@apollo/client';

export default gql`
	query NAV($id: EDITIONKEY!) {
		nav(id: $id) {
			callToAction {
				name
				links {
					displayTitle
					reference {
						uri(edition: $id)
						title
					}
				}
			}
			categories {
				name
				links {
					displayTitle
					reference {
						uri(edition: $id)
						title
					}
				}
			}
			footerText {
				name
				links {
					displayTitle
					reference {
						uri(edition: $id)
						title
					}
				}
			}
			hotTopics {
				name
				links {
					displayTitle
					reference {
						uri(edition: $id)
						title
					}
				}
			}
			notificationBar {
				enabled
				snipeText
				links {
					name
					links {
						displayTitle
						reference {
							uri(edition: $id)
							title
						}
					}
				}
			}
			shows {
				links {
					displayTitle
					reference {
						uri(edition: $id)
						title
						... on Show {
							thumbnail(edition: $id) {
								uri
								title
								agency
								keywords
								sourceWidth
								sourceHeight
							}
						}
					}
				}
			}
			watch {
				name
				links {
					displayTitle
					reference {
						uri(edition: $id)
						title
					}
				}
			}
		}
	}
`;
