import { createContext, useContext } from 'react';

/**
 * @const defaultNavContext
 */
export const defaultNavContext = {
	nav: {
		callToAction: { links: [] },
		categories: { links: [] },
		footerText: { links: [] },
		hotTopics: { links: [] },
		shows: { links: [] }
	},
	seeEditions: false,
	setSeeEditions: () => {},
	toggleSeeEdition: () => {},
	toggleShowMenu: () => {},
	toggleShowSearch: () => {}
};

/**
 * @function NavContext
 * @description React Context for Nav
 * @returns {React.Context}
 */
export const NavContext = createContext(defaultNavContext);

/**
 * @function useNavContext
 * @returns {Function}
 */
export const useNavContext = () => useContext(NavContext);
