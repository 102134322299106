import React from 'react';
import socialLinks from './socialLinks';
import SvgImage from '@/components/icons/SvgImage';
import { usePageContext } from '@/components/layout/page/PageContext';
import Translate from '@/components/i18n/Translate';
import './Follow.scss';

/**
 * @function GlobalFooter
 * @returns {React.ReactElement}
 */

const Follow = () => {
	const { edition, intlLinks } = usePageContext();

	return intlLinks ? (
		<div className="main-menu__section" data-hook="follow-section">
			<span className="main-menu__section-title">
				<Translate word="FOLLOW_E" />
			</span>
			<div className="main-menu__follow-section-social" data-hook="follow-section-social">
				{socialLinks.map(({ image, link, name, targetEditions }, index) => {
					/* "showSocialLink" determines if "targetEditions"
						is absent or if there's a matching target edition */
					const showSocialLink = !targetEditions || targetEditions[edition];

					return showSocialLink ? (
						<a
							className="follow__social-link"
							data-analytics-linklocation="menu-overlay-social-share"
							data-analytics-linkname={name}
							href={intlLinks[link] || link}
							key={`sociallink-${index}`}
							id={`${name}-icon`}
							rel="noopener noreferrer"
							target="_blank"
						>
							<SvgImage className="follow__social-link-img" image={image} />
						</a>
					) : null;
				})}
			</div>
		</div>
	) : null;
};

Follow.displayName = 'Follow';

export default Follow;
